import styles from "../../css/UI.module.css"
import Profile from "../Profile/Profile"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router"
import { Table, Text, Spinner, Box } from "gestalt"
import "gestalt/dist/gestalt.css"
import { useEffect, useState } from "react"
import {
  deleteUser,
  getUserList,
  activateUserAccount,
  ChangeApprovalStatus,
} from "../../firebaseConf"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css" // Import css
import { useMediaQuery } from "react-responsive"
// import { FormControl, InputGroup } from "react-bootstrap"
import { MuiTextfield } from "../InputField"
import moment from "moment"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { approvalStatusOptions } from "../../keyvalue/approvalStatusOptions"

const ClientsList = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 566px)" })

  let navigate = useNavigate()

  const [docs, setDocs] = useState([])
  const [show, setShow] = useState(true)
  const [msg, setMsg] = useState("")
  const [searchString, setSearchString] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const [approvalStatus, setApprovalStatue] = useState("")

  useEffect(() => {
    setMsg("")
    loadData()
  }, [])

  const loadData = () => {
    async function getDocs() {
      const docs_selector = await getUserList()
      setDocs(docs_selector)
      setFilteredData(docs_selector)

      setShow(false)
    }
    setTimeout(getDocs, 1000)
  }
  const handleDelete = (id) => {
    async function deleteUserFromList() {
      const rs = await deleteUser(id)
      if (rs === "deleted") {
        setMsg(id + "已刪除")
      }
      loadData()
    }
    confirmAlert({
      title: "刪除戶口",
      message: "請確認是否刪除" + id + "的戶口?",
      buttons: [
        {
          label: "確認",
          onClick: () => {
            setTimeout(deleteUserFromList, 1000)
          },
        },
        {
          label: "取消",
          onClick: () => {
            return
          },
        },
      ],
    })
  }

  const filterData = () => {
    let data = []

    if (searchString === "") {
      data = docs
    } else {
      for (let i = 0; i < docs.length; i++) {
        if (
          docs[i].idCard.toUpperCase().includes(searchString.toUpperCase()) ||
          docs[i].displayName.toUpperCase().includes(searchString.toUpperCase())
        ) {
          data.push(docs[i])
        }
      }
    }
    setFilteredData(data)
  }

  const checkIsActive = (input) => {
    let userTimestamp = moment(input.timestamp, "MMMM Do YYYY, h:mm:ss a")
    let expiryDate = moment(userTimestamp).add(24, "hours")
    let now_date = moment()
    if (now_date.isSameOrAfter(expiryDate)) {
      return <span style={{ color: "red" }}>disable</span>
    } else {
      return <span style={{ color: "#1ad51a" }}>active</span>
    }
  }

  const handleChangeApprovalStatus = (id, e) => {
    ChangeApprovalStatus(id, e.target.value)
    setApprovalStatue(e.target.value)
  }
  useEffect(() => {
    loadData()
  }, [approvalStatus])

  return (
    <div className={`${styles.container} main_tone`}>
      <Profile back_url={"/admindashboard"} logoLink="/admindashboard" />
      {show ? (
        <Spinner show={show} accessibilityLabel="spinner" />
      ) : (
        <div className="input_container">
          <br />
          <h2>客戶資料</h2>
          <br />
          <MuiTextfield
            id="id_search"
            onChange={(e) => {
              setSearchString(e.target.value)
            }}
            onKeyUp={filterData}
            placeholder="請輸入資料"
            label="搜尋資料"
            value={searchString}
            type="text"
            className={styles.input_field}
          />

          {/* <InputGroup className="mb-3">
            <FormControl
              placeholder="請輸入"
              aria-label="搜尋"
              aria-describedby="basic-addon1"
              onChange={(e) => {
                setSearchString(e.target.value)
                if (e.target.value === "") {
                  loadData()
                }
              }}
            />
            <Button
              variant="outline-secondary"
              id="button-addon2"
              onClick={filterData}
            >
              搜尋
            </Button>
          </InputGroup> */}

          {filteredData.length > 0 && (
            <Box width="100%">
              <Table
                accessibilityLabel="Sticky header"
                maxHeight={500}
                stickyColumns={1}
              >
                <Table.Header sticky>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold">姓名</Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold">身份證號碼</Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold">貸款號碼</Text>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {filteredData.map((doc) => (
                    <Table.RowExpandable
                      key={doc.docId}
                      accessibilityLabel="Expand"
                      accessibilityCollapseLabel="Collapse"
                      expandedContents={
                        <Box maxWidth={"100%"} padding={2} column={12}>
                          <Button
                            variant="contained"
                            className={styles.list_button}
                            onClick={() => {
                              navigate(`/clientdetail/${doc.idCard}`)
                            }}
                          >
                            未簽
                          </Button>
                          <Button
                            variant="contained"
                            className={styles.list_button}
                            onClick={() => {
                              navigate(`/clientsigned/${doc.idCard}`)
                            }}
                          >
                            已簽
                          </Button>
                          <Button
                            variant="contained"
                            className={styles.list_button_changePw}
                            onClick={() => {
                              navigate(`/changepassword/${doc.idCard}`)
                            }}
                          >
                            更改密碼
                          </Button>
                          <Button
                            variant="contained"
                            className={styles.list_button_changePw}
                            onClick={() => {
                              activateUserAccount(doc.idCard)
                              setMsg("已重新激活" + doc?.displayName)
                            }}
                          >
                            重新激活
                          </Button>

                          <Button
                            variant="contained"
                            className={styles.list_button}
                            onClick={() => {
                              handleDelete(doc.idCard)
                            }}
                          >
                            刪除
                          </Button>
                          <FormControl sx={{ width: "100px" }}>
                            <InputLabel id="approvalStatusInputLabelid">
                              批核狀態
                            </InputLabel>
                            <Select
                              sx={{ width: "fit-content", minWidth: "110px" }}
                              labelId="approvalStatus"
                              id="approvalStatus"
                              value={doc?.approvalStatus}
                              label="approval Status"
                              onChange={(e) => {
                                handleChangeApprovalStatus(doc?.idCard, e)
                              }}
                            >
                              {Object.entries(approvalStatusOptions).map(
                                ([value, label]) => (
                                  <MenuItem key={value} value={value}>
                                    {label}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      }
                    >
                      <Table.Cell>
                        <Text weight="bold">{doc?.displayName}</Text>
                        {checkIsActive(doc)}
                      </Table.Cell>
                      <Table.Cell>
                        <Text weight="bold">{doc?.idCard}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text weight="bold">{doc?.loanNo}</Text>
                      </Table.Cell>
                    </Table.RowExpandable>
                  ))}
                </Table.Body>
              </Table>
            </Box>
          )}
          <br />
          {msg !== "" && <h5>{msg}</h5>}
        </div>
      )}
    </div>
  )
}

export default ClientsList
