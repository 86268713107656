import React, { useState } from "react"
import { Button } from "react-bootstrap"
export function ToggleBtnGroup({ ids, values, defaultID, onToggle, disabled }) {
  const [selectedID, setSelectedID] = useState(defaultID)

  const handleToggle = (id, value) => {
    if (selectedID === id) {
      setSelectedID("")
      onToggle("") // Inform parent of the change and reset value.
    } else {
      setSelectedID(id)
      onToggle(value) // Inform parent of the change.
    }
  }

  return (
    <div className="select_group">
      {ids.map((id, index) => (
        <Button
          key={id}
          id={id}
          disabled={disabled}
          onClick={() => handleToggle(id, values[index])}
          value={values[index]}
          style={
            selectedID === id
              ? {
                  color: "#4d2988",
                  backgroundColor: "#ffffff",
                  borderColor: "#4d2988",
                  marginRight: "1rem",
                }
              : {
                  color: "#7c7c7c66",
                  backgroundColor: "#dcdcdc4a",
                  borderColor: "#adadad",
                  boxShadow: "0 0 3px 1px #84848424",
                  marginRight: "1rem",
                }
          }
        >
          {values[index]}
        </Button>
      ))}
    </div>
  )
}
