import styles from "../../css/UI.module.css"
import Profile from "../Profile/Profile"
import Button from "@mui/material/Button"
import { useNavigate, useParams } from "react-router"

import { getUserSignedDocs, getUserUnsignDocs } from "../../firebaseConf"
import { useEffect, useState } from "react"
import { Table, Text, Spinner, Box } from "gestalt"
import "gestalt/dist/gestalt.css"

const ClientSigned = () => {
  const [error, setError] = useState(false)
  const [docs, setDocs] = useState([])
  const [signedDocs, setSignedDocs] = useState([])
  const [show, setShow] = useState(true)

  let num = 0
  let { id } = useParams()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    setShow(true)
    async function getSignedDocs() {
      const signedDocs_selector = await getUserSignedDocs(id)
      setSignedDocs(signedDocs_selector)
      setShow(false)
    }

    async function getUserDocs() {
      const docs_selector = await getUserUnsignDocs(id)
      setDocs(docs_selector)
    }
    getUserDocs()
    setTimeout(() => {
      getUserDocs()
      getSignedDocs()
    }, 500)
  }

  return (
    <div className={styles.container}>
      <Profile back_url={"/clientslist"} logoLink="/admindashboard" />
      {show ? (
        <Spinner show={show} accessibilityLabel="spinner" />
      ) : (
        <div className="input_container">
          {/* Supporting File */}
          <div className="user-control-row">
            <h2>已上載文件</h2>
          </div>
          <hr className={styles.divider}></hr>
          {docs[0] ? (
            <Box width="100%">
              <Table
                accessibilityLabel="Sticky header"
                maxHeight={500}
                stickyColumns={1}
              >
                <Table.Header sticky>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Text weight="bold">文件名稱</Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold">更新時間</Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold"></Text>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {docs[0]?.files
                    .filter((doc) => !doc?.fileName.includes("_contract.pdf"))
                    .reverse()
                    .map((doc, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>
                            <a href={doc?.downloadURL} target="_blank">
                              <p>
                                {++num}. {doc?.fileName}
                              </p>
                            </a>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{doc?.timestamp}</p>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                </Table.Body>
              </Table>
            </Box>
          ) : (
            <h3>沒有文件</h3>
          )}

          <br />
          <h2>已簽文件</h2>
          <hr className={styles.divider}></hr>
          <Box width="100%">
            <Table accessibilityLabel="Sticky header" maxHeight={500}>
              <Table.Header sticky>
                <Table.Row>
                  <Table.HeaderCell>
                    <Text weight="bold">文件名稱</Text>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Text weight="bold">上載時間</Text>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {signedDocs[0]?.files.reverse().map((doc, idx) => {
                  return (
                    <Table.Row key={doc?.docId}>
                      <Table.Cell>
                        <a href={doc?.downloadURL} target="_blank">
                          <p>
                            {++num}. {doc?.fileName}
                          </p>
                        </a>
                      </Table.Cell>
                      <Table.Cell>
                        <p>{doc?.timestamp}</p>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </Box>
        </div>
      )}
    </div>
  )
}

export default ClientSigned
