import { useNavigate } from "react-router"
import "./css/login.css"

import styles from "./css/UI.module.css"
import { useEffect, useState, useContext } from "react"
import { getUserData } from "./firebaseConf"
import Profile from "./components/Profile/Profile"

import "./css/style.css"
import moment from "moment"
import { Form, Button, Alert } from "react-bootstrap"

import { AuthContext } from "../AuthContext"
import TopMenu from "../compoients/TopMenu"

import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import { auth } from "../firebase"

const AdminLogin = ({ data }) => {
  const { state, dispatch } = useContext(AuthContext)

  const [idCard, setIdCard] = useState("")
  const [password, setPassword] = useState("")
  const [phoneNumb, setPhoneNumb] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [error, setError] = useState("")
  const [msg, setMsg] = useState("")

  let navigate = useNavigate()
  const isLogin = JSON.parse(localStorage.getItem("isLogin"))
  const user = localStorage.getItem("user")
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"))

  function checkCurrentUser() {
    if (isLogin && isAdmin) {
      navigate("/admindashboard")
    } else if (isLogin && !isAdmin) {
      navigate("/userdashboard/:" + user)
    }
  }

  useEffect(() => {
    checkCurrentUser()
  }, [])

  const handleLogin = async (e) => {
    e.preventDefault()
    const user = { idCard, password }
    const rs = await getUserData(user)

    if (rs.result) {
      let userTimestamp = rs.userDetail.timestamp
      userTimestamp = moment(userTimestamp, "MMMM Do YYYY, h:mm:ss a")

      let now_date = moment()
      let date_diff = now_date.diff(userTimestamp, "days")

      if (date_diff >= 1) {
        setError("對不起，戶口已過期")
        return
      }

      if (rs.userDetail.password !== password) {
        setError("密碼不正確")
        return
      }

      if (
        rs.userDetail.docId === "superadmin" ||
        rs.userDetail.docId === "sales"
      ) {
        dispatch({
          type: "LOGIN",
          payload: { dashboardURL: "/admindashboard" },
        })
        localStorage.setItem("isAdmin", true)
        localStorage.setItem("isLogin", true)
        localStorage.setItem("dashboardURL", "/admindashboard")
        navigate("/admindashboard")
      } else {
        return setError("帳戶輸入錯誤")
      }
      // } else {
      //   // if (!isSuccess) {
      //   //   setError("請驗證流動電話號碼")
      //   //   return
      //   // }
      //   localStorage.setItem("isAdmin", false)
      //   localStorage.setItem("isLogin", true)
      //   localStorage.setItem("userID", rs.userDetail.docId)
      //   localStorage.setItem(
      //     "dashboardURL",
      //     "/userdashboard/" + rs.userDetail.docId
      //   )

      //   dispatch({
      //     type: "LOGIN",
      //     payload: { dashboardURL: "/userdashboard/" + rs.userDetail.docId },
      //   })
      //   navigate("/userdashboard/" + rs.userDetail.docId)
      // }
    }

    emptyString()

    if (rs.error !== "") setError(rs.error)
  }

  // function rand() {
  //   return Math.random().toString(36).substring(2)
  // }

  // function token() {
  //   return rand() + rand()
  // }

  function emptyString() {
    setIdCard("")
    setPassword("")
    setPhoneNumb("")
    setDisplayName("")
    setError("")
    setMsg("")
  }

  /**firebase phone verify */
  // const [phoneVerified, setPhoneVerified] = useState(false)

  // const countryCode = "+852"
  // const [expandForm, setExpandForm] = useState(false)
  // const [showsendBtn, setShowsendBtn] = useState(true)
  // const [smserror, setSmsError] = useState("")
  // // eslint-disable-next-line no-unused-vars
  // const [OTP, setOTP] = useState("")
  // const [isSentCode, setIsSentCode] = useState(false)
  // const [redo, setRedo] = useState(false)
  // const [isSuccess, setIsSuccess] = useState(false)
  // const [showRecaptcha, setShowRecaptcha] = useState(true)
  // const restart = () => {
  //   setSmsError("")
  //   setExpandForm(false)
  //   setOTP("")
  //   setIsSentCode(false)
  //   setRedo(false)
  //   setIsSuccess(false)
  //   setShowsendBtn(true)
  //   setShowRecaptcha(true)
  // }

  // const sentCode = () => {
  //   setSmsError("")
  //   setExpandForm(true)
  //   setIsSentCode(true)
  // }
  // const failsendCode = () => {
  //   setIsSentCode(false)
  //   setRedo(true)
  //   setIsSuccess(false)
  //   setShowsendBtn(false)
  // }

  // const verified = () => {
  //   setSmsError("")
  //   setPhoneVerified(true)
  //   setIsSuccess(true)
  // }
  // const notVerified = () => {
  //   setPhoneVerified(false)
  //   setRedo(true)
  // }

  // const generateRecaptha = () => {
  //   setShowRecaptcha(true)
  //   window.recaptchaVerifier = new RecaptchaVerifier(
  //     "recaptcha-container",
  //     {
  //       size: "invisible",
  //       callback: (response) => {
  //         // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       },
  //     },
  //     auth
  //   )
  // }

  // const requestOTP = (e) => {
  //   e.preventDefault()
  //   if (phoneNumb.length >= 8) {
  //     generateRecaptha()
  //     let appVerifier = window.recaptchaVerifier
  //     signInWithPhoneNumber(auth, countryCode + phoneNumb, appVerifier)
  //       .then((confirmationResult) => {
  //         window.confirmationResult = confirmationResult
  //         sentCode()
  //       })
  //       .catch((error) => {
  //         //error sms not sent
  //         setShowRecaptcha(false)
  //         if (error.message.includes("too-many-requests")) {
  //           setSmsError("抱歉!嘗試次數太多，請稍後再試。")
  //           failsendCode()
  //         } else if (error.message.includes("INVALID_CODE")) {
  //           setSmsError("驗證碼錯誤，請重新輸入")
  //           failsendCode()
  //         } else if (error.message.includes("TOO_LONG")) {
  //           setSmsError("無效電話號碼，請重新輸入")
  //           failsendCode()
  //         }
  //         failsendCode()
  //       })
  //   } else {
  //     return setSmsError("請輸入正確號碼")
  //   }
  // }

  // const verifyOTP = (e) => {
  //   let otp = e.target.value
  //   setOTP(otp)
  //   if (otp.length === 6) {
  //     //verify otp
  //     let confirmationResult = window.confirmationResult
  //     confirmationResult
  //       .confirm(otp)
  //       .then((result) => {
  //         // User signed in successfully.
  //         //   const user = result.user
  //         verified()
  //       })
  //       .catch((error) => {
  //         if (error.message.includes("invalid")) {
  //           setSmsError("驗證碼錯誤，請重新輸入")
  //         } else if (error.message.includes("expired")) {
  //           setSmsError("驗證碼過期")
  //         }
  //         notVerified()
  //       })
  //   }
  // }
  /**end firebase phone verify */

  /**validator*/

  // useEffect(() => {
  //   if (checkRepeatNumber() === false && checkPhoneFormat() === true) {
  //     setPhoneVerified(true)
  //   } else {
  //     setPhoneVerified(false)
  //   }
  // }, [phoneVerified, phoneNumb])

  // function checkRepeatNumber() {
  //   let flag = false
  //   if (
  //     phoneNumb.includes("11111") ||
  //     phoneNumb.includes("22222") ||
  //     phoneNumb.includes("33333") ||
  //     phoneNumb.includes("44444") ||
  //     phoneNumb.includes("55555") ||
  //     phoneNumb.includes("66666") ||
  //     phoneNumb.includes("77777") ||
  //     phoneNumb.includes("88888") ||
  //     phoneNumb.includes("99999") ||
  //     phoneNumb.includes("00000")
  //   ) {
  //     flag = true
  //     return flag
  //   } else {
  //     flag = false
  //     return flag
  //   }
  // }

  // function checkPhoneFormat() {
  //   let reg = /^[2-9][0-9]{7}$/gm
  //   if (reg.test(phoneNumb) && phoneNumb.length === 8) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }
  /**end validator */

  return (
    <div className={`${styles.container} signature_bg login-container`}>
      <Profile back_url={""} logoLink="/login" currentpage={"login"} />
      <div className="input_container main_tone">
        <h3 className="color6">管理員登入</h3>

        <Form.Group className="input-field  mb-1 mt-1">
          <Form.Label>請輸入管理員帳號</Form.Label>
          <Form.Control
            type="Text"
            id="idCard"
            value={idCard}
            // placeholder="Z 123456 (7)"
            onChange={(e) => setIdCard(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="input-field  mb-3 mt-1">
          <Form.Label>請輸入密碼</Form.Label>
          <Form.Control
            type="password"
            id="password"
            value={password}
            placeholder="密碼"
            onChange={(e) => setPassword(e.target.value)}
          />

          {/* <Form.Label className="mt-2">流動電話號碼驗證</Form.Label>
          <Form.Control
            type="Text"
            id="phoneNumb"
            aria-describedby="phoneHelpBlock"
            value={phoneNumb}
            disabled={isSuccess}
            onChange={(e) => setPhoneNumb(e.target.value)}
            style={{ width: "100%" }}
          />

          <Form.Text id="phoneHelpBlock" className="text-muted">
            請提供有效流動電話號碼，以便接收密碼短訊
          </Form.Text>

          <br />
          {!phoneVerified && phoneNumb.length > 8 && (
            <Form.Label className="alert-label alertcolor">
              請輸入正確號碼
            </Form.Label>
          )}
          <br />

          {expandForm && (
            <Form.Control
              type="otp"
              placeholder="輸入驗證碼"
              onChange={verifyOTP}
              disabled={isSuccess}
              style={{ width: "100%" }}
            />
          )}
          {!expandForm && showsendBtn && (
            <Button
              className="mb-3 purple_btn"
              onClick={requestOTP}
              disabled={!phoneVerified}
            >
              送出驗證碼
            </Button>
          )}

          {showRecaptcha && !redo && (
            <div style={{ zIndex: 999 }} id="recaptcha-container"></div>
          )}

          <div className="d-flex flex-column">
            {redo && (
              <Button className="mb-3 purple_btn mt-2" onClick={restart}>
                重新驗證
              </Button>
            )}

            {isSentCode && !redo && (
              <p className="msg-success ms-2">驗證碼已寄出</p>
            )}
          </div>
          {smserror && (
            <Alert className="mt-2" variant="danger" style={{ width: "20%" }}>
              {smserror}
            </Alert>
          )}
          {isSuccess && <p className="msg-success">✓ 電話號碼驗證成功</p>} */}
        </Form.Group>

        <button
          className={`loginBtn color-orange animatedBtn`}
          onClick={(e) => {
            handleLogin(e)
          }}
        >
          登入
        </button>
        {/* <Button
          variant="contained"
          className={styles.functional_button}
          onClick={(e) => {
            handleLogin(e)
          }}
        >
          登入
        </Button> */}
        {error !== "" && <p className={styles.error_msg}> {error} </p>}
        {/* {msg !== "" && <p className={styles.success_msg}> {msg} </p>} */}
      </div>
    </div>
  )
}

export default AdminLogin
