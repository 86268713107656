import { styled } from "@mui/material/styles"
import { TextField } from "@mui/material"
export const MuiTextfield = styled(TextField)({
  "& label": {
    color: "#4e2a88",
  },
  "& label.Mui-focused": {
    color: "#4e2a88",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#4e2a88",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#4e2a8859",
      boxShadow: "0 0 4px 3px #4e2a882e",
    },
    "&:hover fieldset": {
      borderColor: "#4e2a8859",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#652d90",
      //   boxShadow: "unset ",
    },
  },
})
