import SignaturePad from "react-signature-canvas"
import React, { useEffect, useRef, useState } from "react"
import "./signCanvas.css"
// import { Box, Button } from "gestalt"
import Button from "@mui/material/Button"
import styles from "../../css/UI.module.css"
import SignatureCanvas from "react-signature-canvas"

const SignCanvas = ({ setTrimmedDataUrl }) => {
  //   const [trimmedDataUrl, setTrimmedDataUrl] = useState(null)
  // useEffect(() => {
  //   setTrimmedDataUrl(signRef.current.getTrimmedCanvas().toDataURL("image/png"))
  // }, [])

  const signRef = useRef()
  const clear = (e) => {
    e.preventDefault()
    signRef.current.clear()
    setTrimmedDataUrl("")
  }

  const trim = (e) => {
    e.preventDefault()
    setTrimmedDataUrl(signRef.current.getTrimmedCanvas().toDataURL("image/png"))
  }

  return (
    <div className="signCanvas">
      <div className="sigContainer">
        <SignatureCanvas
          canvasProps={{ className: "sigPad" }}
          ref={signRef}
          clearOnResize={false}
        />

        {/* <SignaturePad canvasProps={{ className: "sigPad" }} ref={signRef} /> */}
        {/* {trimmedDataUrl ? (
          <img className="sigImage" src={trimmedDataUrl} />
        ) : null} */}
      </div>

      <div className="signCanvas-btn">
        <Button
          variant="contained"
          className={styles.signCanvas_button}
          onClick={(e) => clear(e)}
        >
          清除
        </Button>
        <Button
          variant="contained"
          className={styles.signCanvas_button}
          onClick={(e) => {
            trim(e)
          }}
        >
          檢視簽署
        </Button>
      </div>
    </div>
  )
}

export default SignCanvas
