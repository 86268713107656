import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Offcanvas from "react-bootstrap/Offcanvas"
import css from "./css/topMenu.module.css"
import logo from "../images/icon/logo.png"

import "../compoients/css/btn.css"
import { useEffect, useState, useContext } from "react"
import { AuthContext } from "../AuthContext"

const TopMenu = ({ content }) => {
  const { state, dispatch } = useContext(AuthContext)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    if (state.loggedIn || localStorage.getItem("isLogin") === "true") {
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [state, localStorage.getItem("isLogin")])

  const expand = "lg"
  const menuList = (item, index) => {
    if (item.label === "貸款產品") {
      return (
        <NavDropdown
          title={item.label}
          id={`offcanvasNavbarDropdown-expand-${expand}`}
          key={index}
        >
          {content?.loanProduct?.map((subitem, idx) => (
            <div key={idx}>
              {subitem.order !== 1 && <NavDropdown.Divider />}
              <NavDropdown.Item href={subitem.url}>
                {subitem.label}
              </NavDropdown.Item>
            </div>
          ))}
        </NavDropdown>
      )
    } else {
      return (
        <Nav.Link key={index} href={item.url}>
          {item.label}
        </Nav.Link>
      )
    }
  }

  return (
    <div className={css.container}>
      <Navbar key={false} bg="" expand={expand}>
        <Container fluid="sm">
          <Navbar.Brand href="/">
            <img src={logo} alt="letsgo" className={css.logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Offcanvas
              </Offcanvas.Title> */}
              <Navbar.Brand href="/">
                <img src={logo} alt="letsgo" className={css.logo} />
              </Navbar.Brand>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav
                className={`justify-content-end align-items-center flex-grow-1 pe-3 ${css.text}`}
              >
                {content?.main?.map((item, index) => menuList(item, index))}

                {isLoggedIn ? (
                  <Nav.Link
                    href={
                      state.dashboardURL || localStorage.getItem("dashboardURL")
                    }
                  >
                    <button className="dashboard_btn color2">
                      <span>用戶界面</span>
                    </button>
                  </Nav.Link>
                ) : (
                  <Nav.Link href="/login">
                    <button className="menu-login color2">
                      <span>登入</span>
                    </button>
                  </Nav.Link>
                )}

                <Nav.Link href="/apply">
                  <button className=" menu-apply">
                    <span>立即申請</span>
                  </button>
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  )
}

export default TopMenu
