import ApplicationForm from "../compoients/ApplicationForm"
import css from "./css/apply.module.css"
// import bg from "../images/applyForm/bg.png"
import img1 from "../images/applyForm/fillin.png"
import { useEffect } from "react"

const Apply = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  return (
    <div className={css.container}>
      <div className={css.bg_container}>
        <div className={css.title}>
          <h3>歡迎閣下申請</h3>
          <h1>樂高信貸私人貸款</h1>
        </div>
      </div>
      <div className={css.form_container}>
        <ApplicationForm />
      </div>
    </div>
  )
}

export default Apply
