import css from "./css/FilterBtn.module.css"

const FilterBtn = ({ logo_src, text, onClick }) => {
  return (
    <div className={css.container} onClick={onClick}>
      <img src={logo_src} alt="Allarticles" />
      <span>{text}</span>
    </div>
  )
}

export default FilterBtn
