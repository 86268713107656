import React, { useState, useRef } from "react"
import styles from "../../css/UI.module.css"
import { PDFDocument } from "pdf-lib"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import fontkit from "@pdf-lib/fontkit"
import { Spinner } from "gestalt"
import { useNavigate, useParams } from "react-router"
import {
  ChangeApprovalStatus,
  ErrorMsgLogger,
  getDocToSign,
  getUserUnsignDocs,
  uploadSignedDoc,
} from "../../firebaseConf"
import { useEffect } from "react"
import SignCanvas from "../SignCanvas/SignCanvas"
import Profile from "../Profile/Profile"

const DocSign = ({ setModifiedPdf }) => {
  const [hasUpdated, setHasUpdated] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [pdfUrl, setPdfUrl] = useState("") // Initial relative path to the PDF
  const pdfRef = useRef(null) // Ref to download the modified PDF

  const [modifiedPdfBytes, setModifiedPdfBytes] = useState(null)
  const [trimmedDataUrl, setTrimmedDataUrl] = useState("")
  const [isSigned, setIsSigned] = useState(false)

  let { filename, id } = useParams()
  let navigate = useNavigate()

  useEffect(() => {
    getUnsignedDoc()
  }, [])

  useEffect(() => {
    // console.log("isProcessing", isProcessing)
  }, [isProcessing])

  useEffect(() => {
    handleUpdate()
    if (trimmedDataUrl !== "" && trimmedDataUrl) {
      setTimeout(() => {
        setIsSigned(true)
      }, 3000)
    } else {
      setIsSigned(false)
      resetPDF()
    }
  }, [trimmedDataUrl])

  const handleUploadPDF = async () => {
    try {
      uploadDocs(modifiedPdfBytes)
      ChangeApprovalStatus(id, "processing")
    } catch (error) {
      const rs_errorLog = await ErrorMsgLogger(error.message)
      console.log(error.message)
    }
  }

  async function uploadDocs(rs) {
    console.log("start upload")
    const result = await uploadSignedDoc(rs, id, filename)
    console.log(result)
    if (result === "success") {
      navigate("/userdashboard/" + id)
    }
  }

  const getUnsignedDoc = () => {
    async function docToSign() {
      const doc_selector = await getDocToSign(id, filename)
      const filesList = doc_selector[0]?.files
      filesList.forEach(async (element) => {
        // Mark this function as async
        if (element.fileName === filename) {
          const response = await fetch(element.downloadURL)
          const pdfBytes = await response.arrayBuffer()

          // Load the PDF using pdf-lib
          const pdfDoc = await PDFDocument.load(pdfBytes)
          // Continue your processing here

          // Convert pdfBytes to blob and set the URL for the iframe
          const blob = new Blob([pdfBytes], { type: "application/pdf" })
          setPdfUrl(URL.createObjectURL(blob))
        }
      })
    }
    setTimeout(() => {
      docToSign()
    }, 1000)
  }

  const resetPDF = () => {
    setIsProcessing(true)
    setTrimmedDataUrl("")
    getUnsignedDoc()
    setHasUpdated(false) // Reset hasUpdated to false
    setIsProcessing(false)
  }

  const handleUpdate = async () => {
    setIsProcessing(true)
    const fontBytes = await fetch("/NotoSansTC-Regular.ttf").then((res) =>
      res.arrayBuffer()
    )
    // Load the existing PDF

    const existingPdfBytes = await fetch(pdfUrl).then((res) =>
      res.arrayBuffer()
    )

    try {
      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      pdfDoc.registerFontkit(fontkit)

      const customFont = await pdfDoc.embedFont(fontBytes)

      //load image bytes

      const imageBytes = await fetch(trimmedDataUrl).then((res) =>
        res.arrayBuffer()
      )
      const signImg = await pdfDoc.embedPng(imageBytes)
      const imageWidth = signImg.width * 0.3
      const imageHeight = signImg.height * 0.3

      // Modify the PDF based on user input
      const pages = pdfDoc.getPages()
      const Page6 = pages[5]

      Page6.drawImage(signImg, {
        x: 90,
        y: 145,
        width: imageWidth,
        height: imageHeight,
      })

      // Serialize the modified PDF
      const modifiedPdfBytes = await pdfDoc.save()
      setModifiedPdfBytes(modifiedPdfBytes)
      // Convert it to a blob and update the state to re-render the iframe
      const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" })
      setPdfUrl(URL.createObjectURL(blob))
      setHasUpdated(true) // Set hasUpdated to true
      setIsProcessing(false)
    } catch (error) {
      console.error("Error loading PDF document:", error)
    }
  }

  return (
    <div className={styles.container}>
      <Profile
        back_url={"/userdashboard/" + id}
        logoLink={"/userdashboard/" + id}
      />
      <div className="contract-container mt-5">
        <div className="contract-preview-container mb-5">
          <iframe
            className="contract-preview-iframe"
            src={pdfUrl}
            width={"90%"}
          ></iframe>
        </div>
        <h4>
          若同意及承認以上文件包括的所有條款，請在下方空白地方簽署並確認。
        </h4>
        <div className="contract-sign-canvas">
          <SignCanvas setTrimmedDataUrl={setTrimmedDataUrl} />
        </div>

        <div className="contract-input-container">
          <div className="button-container">
            {/* <Spinner show={isProcessing} accessibilityLabel="spinner" /> */}
            <Button
              variant="contained"
              className={styles.signCanvas_button}
              onClick={handleUploadPDF}
              disabled={!isSigned}
            >
              確認簽署
            </Button>
          </div>
        </div>
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          className="mt-5"
          variant="contained"
          color="primary"
          ref={pdfRef}
          href={pdfUrl}
          download="modified_contract.pdf"
        >
          下載 PDF
        </Button>
      </div> */}
      </div>
    </div>
  )
}

export default DocSign
