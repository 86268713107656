import React, { useRef, useState } from "react"
import css from "./css/aboutus.module.css"
import banner from "../images/aboutus/banner.png"
import timeline from "../images/aboutus/Timeline.png"
import logo from "../images/icon/logo.png"
import left_arrow from "../images/compoients/left_arrow.png"
import right_arrow from "../images/compoients/right_arrow.png"
import Carousel from "nuka-carousel"
import { useMediaQuery } from "react-responsive"

const AboutUs = () => {
  const sponsorLogos = [logo, logo, logo, logo, logo, logo, logo, logo, logo]
  const isMobile = useMediaQuery({ query: "(max-width: 520px)" })
  const isMobile_toogle = useMediaQuery({ query: "(max-width: 996px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 961px)" })

  return (
    <div className="loanProduct-container">
      <div
        className="normal-banner"
        style={{ backgroundImage: `url(${banner}` }}
      >
        <h1 style={{ color: "white", marginTop: "-2rem" }}>關於我們</h1>
      </div>
      <div>
        <div className={css.milestone}>
          <h1 className="sub_title">發展里程</h1>
          <h4>
            樂高信貸Let’s Go Credit由資深銀行及財務團隊組合而成,
            多位成員都有超過十年以上豐富經驗及專業知識。
          </h4>
          <div className={css.timeline_wrapper}>
            <span className={css.timeline_text1}>2019年</span>
            <span className={css.timeline_text2}>集團正式成立</span>
            <span className={css.timeline_text3}>2020年</span>
            <span className={css.timeline_text4}>團隊人數增至50多人</span>
            <span className={css.timeline_text5}>2021年</span>
            <span className={css.timeline_text6}>業務及生意穩健發展</span>
            <span className={css.timeline_text7}>2023年</span>
            <span className={css.timeline_text8}>團隊人員增至100人以上</span>
            <img src={timeline} alt="timeline" className={css.timeline} />
          </div>
        </div>

        {/* <div className={css.container}>
          <h1 className="sub_title">獲得多家投資者支持</h1>
          <h4 style={{ marginBottom: "10rem" }}>
            Let's Go Credit獲得全球多個投資機構支持，其中包括XXXXXXX...等
          </h4>
        </div> */}

        {/* <div className={css.container}>
          <h1 className="sub_title">企業榮譽</h1>
          <h4 style={{ marginBottom: "10rem" }}>
            Let's Go Credit獲得XXXXX等的認可
          </h4>
        </div> */}

        {/* <div className="carousel-container">
          <Carousel
            renderCenterLeftControls={({ previousSlide }) => (
              <button onClick={previousSlide} className="slide-arrow-left">
                <img src={left_arrow} alt="prev" className="slide-arrow-img" />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button onClick={nextSlide} className="slide-arrow-right">
                <img src={right_arrow} alt="next" className="slide-arrow-img" />
              </button>
            )}
            slidesToShow={isMobile ? 1 : 3}
            slidesToScroll={3}
            wrapAround={true}
            renderBottomCenterControls={null} // This will hide the dots (pagination)
            withoutControls={false} // Ensures that the controls show up. Remove this line if not necessary.
          >
            {sponsorLogos.map((item, idx) => (
              <div className="slide-wrapper" key={idx}>
                <img src={item} alt="" className="carousel-image" />
              </div>
            ))}
          </Carousel>
        </div> */}
      </div>
    </div>
  )
}

export default AboutUs
