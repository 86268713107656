import { useState } from "react"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { OutlinedInput, FormHelperText } from "@mui/material"
import css from "./css/estimateForm.module.css"
import bg from "../images/home/part4-people.png"
import { EstimateApplyBtn } from "./Btn"
import { ENV } from "../config"
import moment from "moment"

const EstimateForm = () => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [address, setAddress] = useState("")
  const [byPhone, setByPhone] = useState(false)
  const [byWhatsApp, setByWhatsApp] = useState(false)
  const [agreeTerms1, setAgreeTerms1] = useState(false)
  const [agreeTerms2, setAgreeTerms2] = useState(false)
  const [msg, setMsg] = useState("")
  const [errMsg, setErrMsg] = useState("")

  const resetData = () => {
    setName("")
    setPhone("")
    setAddress("")
    setByPhone(false)
    setByWhatsApp(false)
    setAgreeTerms1(false)
    setAgreeTerms2(false)
    setErrMsg("")
  }

  const handleSubmit = () => {
    setMsg("")
    setErrMsg("")
    const regex = /^[456789][0-9]{7}$/
    if (!regex.test(phone)) {
      setErrMsg("請輸入正確電話號碼")
      return
    }

    //validation
    if (!(name && phone && address) && (byPhone || byWhatsApp)) {
      return setErrMsg("請填寫所需資料")
    }

    const data = {
      name: name,
      phone: phone,
      address: address,
      byPhone: byPhone,
      byWhatsApp: byWhatsApp,
      agreeTerms1: agreeTerms1,
      agreeTerms2: agreeTerms2,
      timestamp: moment().utcOffset(8).format("YYYY-MM-DD HH:mm:ss"),
    }
    fetch(ENV + "/api/v1/valuation", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          setMsg("成功送出")
          resetData()
          // navigate("/thankyou")
        } else {
          setMsg("")
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
      .finally(() => {})
  }

  return (
    <div className={css.form_container}>
      <div className={css.form_left}>
        <h1>物業估價</h1>
        <br />
        <p>
          為確保客戶物業估價更準確，我們將會根據客戶提供的相關資料進行綜合評估，稍後將會有專業人員聯繫您
        </p>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeTerms1}
                onChange={({ target }) => {
                  setAgreeTerms1(target.checked)
                }}
              />
            }
            label="我同意並接受個人資料會被收集作貸款申請用途"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeTerms2}
                onChange={({ target }) => {
                  setAgreeTerms2(target.checked)
                }}
              />
            }
            label="我了解在貸款申請途中，樂高信貸會對我的個人資料絕對保密"
          />
        </FormGroup>
        <div className={css.form_element}>
          <FormHelperText
            id="outlined-name-helper-text"
            className={css.form_helper_text}
          >
            <span className={css.requiredStar}>*</span>姓名
          </FormHelperText>
          <OutlinedInput
            id="outlined-name"
            value={name}
            sx={{ width: "100%" }}
            label="姓名"
            aria-describedby="outlined-name-helper-text"
            onChange={(e) => setName(e.target.value)}
            name="name"
          />
        </div>
        <div className={css.form_element}>
          <FormHelperText
            id="outlined-name-helper-text"
            className={css.form_helper_text}
          >
            <span className={css.requiredStar}>*</span>電話
          </FormHelperText>
          <OutlinedInput
            id="outlined-name"
            value={phone}
            label="電話"
            inputProps={{ inputMode: "numeric", pattern: "\\d*" }}
            sx={{ width: "100%" }}
            onChange={(e) => {
              if (e.target.value === "" || /^[0-9]+$/.test(e.target.value)) {
                setPhone(e.target.value)
              }
            }}
            name="phone"
          />
        </div>
        <div className={css.form_element}>
          <FormHelperText
            id="outlined-name-helper-text"
            className={css.form_helper_text}
          >
            <span className={css.requiredStar}>*</span>物業地址
          </FormHelperText>

          <OutlinedInput
            id="outlined-multiline-static"
            label="物業地址"
            value={address}
            name="address"
            multiline
            sx={{ width: "100%" }}
            rows={4}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>

        <div className="d-flex align-items-center">
          <span style={{ paddingRight: "20px" }}>
            <span className={css.requiredStar}>*</span>聯絡方式
          </span>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={byPhone}
                  onChange={({ target }) => {
                    setByPhone(target.checked)
                  }}
                />
              }
              label="電話"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={byWhatsApp}
                  onChange={({ target }) => {
                    setByWhatsApp(target.checked)
                  }}
                />
              }
              label="WhatsApp"
            />
          </FormGroup>
        </div>

        <div className="text-center pt-3">
          <EstimateApplyBtn handle={handleSubmit} />
          {msg && <p>{msg}</p>}
          {errMsg && <p className="alertcolor">{errMsg}</p>}
        </div>
      </div>
      <div className={css.form_right}>
        <img src={bg} alt="" className={css.bg} />
      </div>
    </div>
  )
}

export default EstimateForm
