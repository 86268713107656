import React, { useEffect, useState, useMemo } from "react"
import css from "./css/blog.module.css"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import { Autoplay, Pagination as swiperPagination, Navigation } from "swiper"
import Pagination from "@mui/material/Pagination"
import "../compoients/css/reactSwiper.css"
import "./css/blog.css"
import FilterBtn from "../compoients/FilterBtn"
import filter1 from "../images/button/Allarticles.png"
import filter2 from "../images/button/Loaninformation.png"
import filter3 from "../images/button/Investmenttips.png"
import filter4 from "../images/button/Encyclopedias.png"
import { Link } from "react-router-dom"
import useFetch from "../hooks/useFetch"
import { ENV } from "../config"
import { FormDatatext } from "../hooks/useFormatDatatext"

const Blog = () => {
  const { data } = useFetch(ENV + "/api/v1/post")
  const bannerData = useFetch(ENV + "/api/v1/banner")
  const [postData, setPostData] = useState(null)
  const [bannerList, setBannerList] = useState([])

  //setup pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(4) // Number of posts per page

  //filter blog post
  const [selectedCategory, setSelectedCategory] = useState("")
  const handleAllArticlesClick = () => {
    setSelectedCategory("")
    setCurrentPage(1) // Reset to the first page
  }

  const handleLoanInfoClick = () => {
    setSelectedCategory("loanInfo")
    setCurrentPage(1) // Reset to the first page
  }

  const handleLoanTipsClick = () => {
    setSelectedCategory("loanTips")
    setCurrentPage(1) // Reset to the first page
  }

  const handleManageClick = () => {
    setSelectedCategory("manage")
    setCurrentPage(1) // Reset to the first page
  }

  useEffect(() => {
    if (data) {
      setPostData(data)
    }
  }, [data])

  const filteredBanners = useMemo(() => {
    if (bannerData && bannerData.data) {
      return bannerData.data.filter((item) => item.location === "blog")
    }
    return []
  }, [bannerData])

  useEffect(() => {
    setBannerList(filteredBanners)
  }, [])

  // Calculate the current posts to display
  const filteredPosts = useMemo(() => {
    return selectedCategory
      ? postData?.filter((post) => post.category === selectedCategory)
      : postData
  }, [selectedCategory, postData])
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = filteredPosts?.slice(indexOfFirstPost, indexOfLastPost)

  // Change page
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage)
  }

  return (
    <div className={css.container}>
      <Swiper
        cssMode={true}
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, swiperPagination, Navigation]}
        className="logo_swiper"
      >
        {bannerList?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={css.swiperSlide_container}>
              <img src={item.banner} alt="" className={css.swiper_banner_img} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className={css.filter_container}>
        <div className={css.filter_block}>
          <FilterBtn
            logo_src={filter1}
            text="全部文章"
            onClick={handleAllArticlesClick}
          />
          <FilterBtn
            logo_src={filter2}
            text="貸款資訊"
            onClick={handleLoanInfoClick}
          />
        </div>
        <div className={css.filter_block}>
          <FilterBtn
            logo_src={filter3}
            text="投資貼士"
            onClick={handleLoanTipsClick}
          />
          <FilterBtn
            logo_src={filter4}
            text="理財百科"
            onClick={handleManageClick}
          />
        </div>
      </div>

      <div className={css.article_container}>
        {currentPosts?.length > 0 && (
          <h1 className="pb-5 align-self-start">最新文章</h1>
        )}
        {currentPosts?.length > 0 ? (
          currentPosts.map((item, index) => (
            <div className={css.article_content} key={index}>
              <div className={css.article_img_wrapper}>
                <Link to={`/blog/${item?.title}`}>
                  <img src={item.banner} alt="" className={css.article_img} />
                </Link>
              </div>
              <div className={css.content_container}>
                <Link to={`/blog/${item?.title}`}>
                  <h2>{item.title}</h2>
                </Link>
                <p>{item.publishDate}</p>
                <Link to={`/blog/${item?.title}`}>
                  <p>{FormDatatext(item.content.slice(0, 50) + "...")}</p>
                </Link>
              </div>
            </div>
          ))
        ) : (
          <div>未有文章</div>
        )}
        {currentPosts?.length > 0 ? (
          <Pagination
            className={css.pagination}
            count={Math.ceil(filteredPosts?.length / postsPerPage)}
            page={currentPage}
            onChange={handleChangePage}
            // color="primary"
            shape="rounded"
            sx={{ display: "flex", justifyContent: "center" }} // Center the pagination
          />
        ) : null}
      </div>
    </div>
  )
}

export default Blog
