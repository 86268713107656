import css from "./css/applicationForm.module.css"
import "./css/applicationForm.css"
import { Form, Button, Alert } from "react-bootstrap"
import CurrencyInput from "react-currency-input-field"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import { useState, useEffect } from "react"
import { NextStepBtn, SubmitBtnBig, PrevStepBtn } from "./Btn"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import img1 from "../images/applyForm/target1.png"
import img2 from "../images/applyForm/target2.png"
import img3 from "../images/applyForm/target3.png"
import img4 from "../images/applyForm/target4.png"

import moment from "moment"

import { ENV } from "../config"
import { useNavigate } from "react-router-dom"
import { auth } from "../firebase"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import { createUser } from "../signature/firebaseConf"
import { ToggleBtnGroup } from "./ToggleBtnGroup"

const ApplicationForm = () => {
  const navigate = useNavigate()
  const [step, setStep] = useState(1)
  const [title, setTitle] = useState("")
  const [title2, setTitle2] = useState("")

  /**step1 */
  const [loanTarget, setLoanTarget] = useState("")
  const [loan_selectID, setLoan_selectID] = useState("")
  const [submissionID, setSubmissionID] = useState("")

  /**end step 1 */

  /**step2 */
  const [phoneNumb, setPhoneNumb] = useState("")
  const [lastName, setLastName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [idCard, setIdCard] = useState("")
  const [startDate, setStartDate] = useState(new Date())
  const [birthDate, setBirthDate] = useState("")
  const [sex, setSex] = useState("")
  const [sex_selectID, setSex_selectID] = useState("")
  const [paymentTerms, setPaymentTerms] = useState()
  const [paymentTermsID, setPaymentTerms_ID] = useState("")
  const [employment, setEmployment] = useState()
  const [employmentID, setEmploymentID] = useState("")
  const [sameToPropertyInfo, setSameToPropertyInfo] = useState("")

  /**end step 2 */

  /**step 3 */

  const [amount, setAmount] = useState("")
  const [occupation, setOccupation] = useState("全職")
  const [occ_selectID, setOcc_selectID] = useState("ftEmployee")
  const [payMethod, setPayMethod] = useState("自動轉賬")
  const [pay_selectID, setPay_selectID] = useState("pay1")
  const [income, setIncome] = useState("")
  const [agreeNext, setAgreeNext] = useState("")
  const [industry, setIndustry] = useState("bank")

  const [liveBlock, setLiveBlock] = useState("")
  const [liveUnit, setLiveUnit] = useState("")
  const [liveFloor, setLiveFloor] = useState("")
  const [liveAddress, setLiveAddress] = useState("")
  const [liveRegion, setLiveRegion] = useState("")
  const [liveMethod, setLiveMethod] = useState()
  const [liveMethodID, setLiveMethodID] = useState("")

  const [propertyBlock, setPropertyBlock] = useState("")
  const [propertyUnit, setPropertyUnit] = useState("")
  const [propertyFloor, setPropertyFloor] = useState("")
  const [propertyAddress, setPropertyAddress] = useState("")
  const [propertyRegion, setPropertyRegion] = useState("")
  const [propertyMethod, setPropertyMethod] = useState()
  const [propertyMethodID, setPropertyMethodID] = useState("")
  const [propertyMortageState, setPropertyMortageState] = useState("")

  const [carTypeMode, setCarTypeMode] = useState("")
  const [carTypeModeID, setCarTypeModeID] = useState("")
  const [carBrand, setCarBrand] = useState("")
  const [carColor, setCarColor] = useState("")
  const [carFirstMile, setCarFirstMile] = useState("")
  const [carMile, setCarMile] = useState("")
  const [carLuxInfo, setCarLuxInfo] = useState("")
  const [carBrandFactory, setCarBrandFactory] = useState("")
  const [carModel, setCarModel] = useState("")
  const [carYear, setCarYear] = useState("")
  const [nationality, setNationality] = useState("")

  const [companyName, setCompanyName] = useState("")
  const [companyPhone, setCompanyPhone] = useState()
  const [companyAddress, setCompanyAddress] = useState("")
  const [position, setPosition] = useState("")

  const [carType, setCarType] = useState()
  const [carTypeID, setCarTypeID] = useState("")
  /**step3 */

  /**firebase phone verify */
  const [phoneVerified, setPhoneVerified] = useState(false)

  const countryCode = "+852"
  const [expandForm, setExpandForm] = useState(false)
  const [showsendBtn, setShowsendBtn] = useState(true)
  const [error, setError] = useState("")
  // eslint-disable-next-line no-unused-vars
  const [OTP, setOTP] = useState("")
  const [isSentCode, setIsSentCode] = useState(false)
  const [redo, setRedo] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [showRecaptcha, setShowRecaptcha] = useState(true)
  const restart = () => {
    setError("")
    setExpandForm(false)
    setOTP("")
    setIsSentCode(false)
    setRedo(false)
    setIsSuccess(false)
    setShowsendBtn(true)
    setShowRecaptcha(true)
  }

  const sentCode = () => {
    setError("")
    setExpandForm(true)
    setIsSentCode(true)
  }
  const failsendCode = () => {
    setIsSentCode(false)
    setRedo(true)
    setIsSuccess(false)
    setShowsendBtn(false)
  }

  const verified = () => {
    setError("")
    setPhoneVerified(true)
    setIsSuccess(true)
  }
  const notVerified = () => {
    setPhoneVerified(false)
    setRedo(true)
  }

  const generateRecaptha = () => {
    setShowRecaptcha(true)
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    )
  }

  const requestOTP = (e) => {
    e.preventDefault()
    if (phoneNumb.length >= 8) {
      generateRecaptha()
      let appVerifier = window.recaptchaVerifier
      signInWithPhoneNumber(auth, countryCode + phoneNumb, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult
          sentCode()
        })
        .catch((error) => {
          //error sms not sent
          setShowRecaptcha(false)
          if (error.message.includes("too-many-requests")) {
            setError("抱歉!嘗試次數太多，請稍後再試。")
            failsendCode()
          } else if (error.message.includes("INVALID_CODE")) {
            setError("驗證碼錯誤，請重新輸入")
            failsendCode()
          } else if (error.message.includes("TOO_LONG")) {
            setError("無效電話號碼，請重新輸入")
            failsendCode()
          }
          failsendCode()
        })
    } else {
      return setError("請輸入正確號碼")
    }
  }

  const verifyOTP = (e) => {
    let otp = e.target.value
    setOTP(otp)
    if (otp.length === 6) {
      //verify otp
      let confirmationResult = window.confirmationResult
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          //   const user = result.user
          verified()
        })
        .catch((error) => {
          if (error.message.includes("invalid")) {
            setError("驗證碼錯誤，請重新輸入")
          } else if (error.message.includes("expired")) {
            setError("驗證碼過期")
          }
          notVerified()
        })
    }
  }
  /**end firebase phone verify */

  /**validator*/

  useEffect(() => {
    if (checkRepeatNumber() === false && checkPhoneFormat() === true) {
      setPhoneVerified(true)
    } else {
      setPhoneVerified(false)
    }
  }, [phoneVerified, phoneNumb])

  useEffect(() => {
    const result = checkNormalSequenceNumber("12345")
    console.log("check sequence", result)
  }, [])

  function checkRepeatNumber() {
    let flag = false
    if (
      phoneNumb.includes("11111") ||
      phoneNumb.includes("22222") ||
      phoneNumb.includes("33333") ||
      phoneNumb.includes("44444") ||
      phoneNumb.includes("55555") ||
      phoneNumb.includes("66666") ||
      phoneNumb.includes("77777") ||
      phoneNumb.includes("88888") ||
      phoneNumb.includes("99999") ||
      phoneNumb.includes("00000")
    ) {
      flag = true
      return flag
    } else {
      flag = false
      return flag
    }
  }

  function checkNormalSequenceNumber(numb) {
    let flag = true
    let countSequence = 0

    for (let i = 1; i < numb.length; i++) {
      let diff = numb.substring(i) - numb.substring(i + 1)
      if (diff === 1) {
        countSequence++
      }
      if (countSequence >= 5) {
        flag = false
      }
    }
  }

  function checkPhoneFormat() {
    let reg = /^[2-9][0-9]{7}$/gm
    if (reg.test(phoneNumb) && phoneNumb.length === 8) {
      return true
    } else {
      return false
    }
  }
  /**end validator */

  function handleSaveFirstStep(data) {
    //check if the record is already exist
    if (submissionID) {
      console.log("Record ID:", submissionID)
      return
    }

    let apiURL = ""
    if (loan_selectID === "loan1" || loan_selectID === "loan2") {
      apiURL = "/api/v1/application"
    } else if (loan_selectID === "loan3") {
      apiURL = "/api/v1/mortgage"
    } else if (loan_selectID === "loan4") {
      apiURL = "/api/v1/collateral"
    }

    fetch(ENV + apiURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`)
        }
        return res.json() // must return res json to get the response
      })
      .then((response) => {
        if (response.id) {
          console.log("Record ID:", response.id)
          setSubmissionID(response.id)
        } else {
          console.error("No ID found in response")
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
      .finally(() => {})
  }

  function handleNextStep() {
    if (step === 1) {
      if (loanTarget === "") {
        alert("請選擇貸款目的")
        return
      }
      //save the loanTarget to the database and get the id
      let data = {
        loanTarget: loanTarget,
        phoneNumb: phoneNumb,
        timestamp: moment().utcOffset(8).format("YYYY-MM-DD HH:mm:ss"),
      }
      handleSaveFirstStep(data)
    }
    if (step === 3) {
      if (
        phoneNumb === "" ||
        lastName === "" ||
        firstName === "" ||
        idCard === "" ||
        birthDate === "" ||
        sex === "" ||
        checkRepeatNumber() === true ||
        checkPhoneFormat() === false
      ) {
        alert("請填寫正確資料")
        return
      }
    }

    setStep(step + 1)
    window.scrollTo({ top: 0, behavior: "instant" })
  }

  function handlePrevStep() {
    setStep(step - 1)
    window.scrollTo({ top: 0, behavior: "instant" })
  }

  useEffect(() => {
    switch (step) {
      case 1:
      case 2:
        setTitle("歡迎使用樂高信貸")
        setTitle2(
          "樂高信貸為閣下提供私人貸款、免TU貸款、業主貸款、清卡數及汽車抵押等多種貸款服務！"
        )
        break

      case 3:
      case 4:
        setTitle("閣下已初步通過審核，離成功貸款又近一步")
        setTitle2(
          "只需提交簡單文件，即可獲知批核結果，輕鬆完成貸款辦理，全程無需現身！"
        )
        break
      case 5:
        setTitle("即刻遞交文件申請貸款資格！")
        setTitle2(
          "只需按照以下步驟即可快速完成網上申請，一經批核，即時過數！助閣下解決資金問題！"
        )
        break

      default:
        break
    }

    if (step === 1) {
      if (loan_selectID !== "") {
        const loan = document.getElementById(loan_selectID).style
        loan.border = "1px solid #512d8a"
      }
      let loanList = ["loan1", "loan2", "loan3", "loan4"]
      if (loan_selectID !== "") {
        loanList = loanList.filter((item) => item !== loan_selectID)
      }
      for (let i = 0; i < loanList.length; i++) {
        let item = document.getElementById(loanList[i]).style
        item.border = "0"
      }
    }
    if (step === 2) {
      if (paymentTermsID !== "") {
        const sex = document.getElementById(paymentTermsID).style
        sex.color = "#4d2988"
        sex.backgroundColor = "#ffffff"
        sex.borderColor = "#4d2988"
      }
      let paymentTermsIDLisst = [
        "payment6",
        "payment12",
        "payment18",
        "payment24",
        "payment30",
        "payment36",
        "payment42",
        "payment48",
        "payment54",
        "payment60",
      ]
      if (paymentTermsID !== "") {
        paymentTermsIDLisst = paymentTermsIDLisst.filter(
          (item) => item !== paymentTermsID
        )
      }
      for (let i = 0; i < paymentTermsIDLisst.length; i++) {
        let item = document.getElementById(paymentTermsIDLisst[i]).style
        item.color = "#7c7c7c66"
        item.backgroundColor = "#dcdcdc4a"
        item.borderColor = "#adadad"
        item.boxShadow = "0 0 3px 1px #84848424"
      }
    }
    if (step === 3) {
      if (sex_selectID !== "") {
        const sex = document.getElementById(sex_selectID).style
        sex.color = "#4d2988"
        sex.backgroundColor = "#ffffff"
        sex.borderColor = "#4d2988"
      }
      let sexList = ["sex1", "sex2"]
      if (sex_selectID !== "") {
        sexList = sexList.filter((item) => item !== sex_selectID)
      }
      for (let i = 0; i < sexList.length; i++) {
        let item = document.getElementById(sexList[i]).style
        item.color = "#7c7c7c66"
        item.backgroundColor = "#dcdcdc4a"
        item.borderColor = "#adadad"
        item.boxShadow = "0 0 3px 1px #84848424"
      }
    }

    if (step === 4) {
      // if (liveMethodID !== "") {
      //   const sex = document.getElementById(liveMethodID).style
      //   sex.color = "#4d2988"
      //   sex.backgroundColor = "#ffffff"
      //   sex.borderColor = "#4d2988"
      // }
      // let liveMethodIdList = ["liveMethodSelf"]
      // if (liveMethodID !== "") {
      //   liveMethodIdList = liveMethodIdList.filter(
      //     (item) => item !== liveMethodID
      //   )
      // }

      // for (let i = 0; i < liveMethodIdList.length; i++) {
      //   let item = document.getElementById(liveMethodIdList[i]).style
      //   item.color = "#7c7c7c66"
      //   item.backgroundColor = "#dcdcdc4a"
      //   item.borderColor = "#adadad"
      //   item.boxShadow = "0 0 3px 1px #84848424"
      // }

      if (loan_selectID === "loan3") {
        if (propertyMethodID !== "") {
          const propertyMethod = document.getElementById(propertyMethodID).style
          propertyMethod.color = "#4d2988"
          propertyMethod.backgroundColor = "#ffffff"
          propertyMethod.borderColor = "#4d2988"
        }
        let propertyMethodIDList = ["propertyMethodSelf", "propertyMethodRent"]
        if (propertyMethodID !== "") {
          propertyMethodIDList = propertyMethodIDList.filter(
            (item) => item !== propertyMethodID
          )
        }
        for (let i = 0; i < propertyMethodIDList.length; i++) {
          let item = document.getElementById(propertyMethodIDList[i]).style
          item.color = "#7c7c7c66"
          item.backgroundColor = "#dcdcdc4a"
          item.borderColor = "#adadad"
          item.boxShadow = "0 0 3px 1px #84848424"
        }
      }
    }
    if (step === 5) {
      if (loan_selectID === "loan1" || loan_selectID === "loan2") {
        if (occ_selectID !== "") {
          const occ = document.getElementById(occ_selectID).style
          occ.color = "#4d2988"
          occ.backgroundColor = "#ffffff"
          occ.borderColor = "#4d2988"
        }

        let occList = ["ftEmployee", "ptEmployee", "freelancer"]
        if (occ_selectID !== "") {
          occList = occList.filter((item) => item !== occ_selectID)
        }
        for (let i = 0; i < occList.length; i++) {
          let item = document.getElementById(occList[i]).style
          item.color = "#7c7c7c66"
          item.backgroundColor = "#dcdcdc4a"
          item.borderColor = "#adadad"
          item.boxShadow = "0 0 3px 1px #84848424"
        }
        if (pay_selectID !== "") {
          const pay = document.getElementById(pay_selectID).style
          pay.color = "#4d2988"
          pay.backgroundColor = "#ffffff"
          pay.borderColor = "#4d2988"
        }
        let payList = ["pay1", "pay2", "pay3"]
        if (pay_selectID !== "") {
          payList = payList.filter((item) => item !== pay_selectID)
        }
        for (let i = 0; i < payList.length; i++) {
          let item = document.getElementById(payList[i]).style
          item.color = "#7c7c7c66"
          item.backgroundColor = "#dcdcdc4a"
          item.borderColor = "#adadad"
          item.boxShadow = "0 0 3px 1px #84848424"
        }

        if (employmentID !== "") {
          const employmentItem = document.getElementById(employmentID).style
          employmentItem.color = "#4d2988"
          employmentItem.backgroundColor = "#ffffff"
          employmentItem.borderColor = "#4d2988"
        }
        let employmentIDList = [
          "lessYearEmployment",
          "overThreeEmployment",
          "oneToThreeEmployment",
        ]
        if (employmentID !== "") {
          employmentIDList = employmentIDList.filter(
            (item) => item !== employmentID
          )
        }
        for (let i = 0; i < employmentIDList.length; i++) {
          let item = document.getElementById(employmentIDList[i]).style
          item.color = "#7c7c7c66"
          item.backgroundColor = "#dcdcdc4a"
          item.borderColor = "#adadad"
          item.boxShadow = "0 0 3px 1px #84848424"
        }
      } else if (loan_selectID === "loan4") {
        if (carTypeID !== "") {
          const carTypeItem = document.getElementById(carTypeID).style
          carTypeItem.color = "#4d2988"
          carTypeItem.backgroundColor = "#ffffff"
          carTypeItem.borderColor = "#4d2988"
        }
        let carTypeIDList = ["privateCar", "vanCar", "itemCar"]
        if (carTypeID !== "") {
          carTypeIDList = carTypeIDList.filter((item) => item !== carTypeID)
        }
        for (let i = 0; i < carTypeIDList.length; i++) {
          let item = document.getElementById(carTypeIDList[i]).style
          item.color = "#7c7c7c66"
          item.backgroundColor = "#dcdcdc4a"
          item.borderColor = "#adadad"
          item.boxShadow = "0 0 3px 1px #84848424"
        }
        if (carTypeModeID !== "") {
          const carTypeModeItem = document.getElementById(carTypeModeID).style
          carTypeModeItem.color = "#4d2988"
          carTypeModeItem.backgroundColor = "#ffffff"
          carTypeModeItem.borderColor = "#4d2988"
        }
        let carTypeModeIDList = ["firstHand", "secondHand"]
        if (carTypeModeID !== "") {
          carTypeModeIDList = carTypeModeIDList.filter(
            (item) => item !== carTypeModeID
          )
        }
        for (let i = 0; i < carTypeModeIDList.length; i++) {
          let item = document.getElementById(carTypeModeIDList[i]).style
          item.color = "#7c7c7c66"
          item.backgroundColor = "#dcdcdc4a"
          item.borderColor = "#adadad"
          item.boxShadow = "0 0 3px 1px #84848424"
        }
      }
    }

    return () => {}
  }, [
    step,
    loanTarget,
    phoneNumb,
    lastName,
    firstName,
    idCard,
    birthDate,
    sex,
    amount,
    occupation,
    payMethod,
    income,
    paymentTermsID,
    paymentTerms,
    occ_selectID,
    pay_selectID,
    loan_selectID,
    liveMethod,
    liveMethodID,
    propertyMethod,
    propertyMethodID,
    sex_selectID,
    employment,
    employmentID,
    carType,
    carTypeID,
    carTypeModeID,
    carTypeMode,
  ])

  function handleDate(e) {
    setStartDate(e)
    const newDate = new Date(e)
    let day = "/" + (newDate.getUTCDate() + 1)
    let month = "/" + (newDate.getUTCMonth() + 1)
    let year = newDate.getUTCFullYear()
    setBirthDate(year + month + day)
  }

  //update the submission record
  const updateRecord = (updatedData) => {
    let apiURL = ""
    if (loan_selectID === "loan1" || loan_selectID === "loan2") {
      apiURL = `/api/v1/application/${submissionID}`
    } else if (loan_selectID === "loan3") {
      apiURL = `/api/v1/mortgage/${submissionID}`
    } else if (loan_selectID === "loan4") {
      apiURL = `/api/v1/collateral/${submissionID}`
    }

    fetch(`${ENV + apiURL}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedData),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`)
        }
        return res.json()
      })
      .then((response) => {
        console.log("Record updated successfully:", response)
        return "ok"
      })
      .catch((err) => {
        console.log("Error updating record:", err.message)
      })
  }

  // // Example usage after receiving the ID
  // const handleUpdate = (loan_selectID) => {
  //   const updatedData = {
  //     idCard: "newIdCard",
  //     // Include other fields that need to be updated
  //   };

  // };

  //send Data
  const handleSubmit = async () => {
    if (step === 3) {
      if (
        // amount === "" ||
        // amount === "0" ||
        occupation === "" ||
        payMethod === "" ||
        income === "" ||
        income === "0"
      ) {
        alert("請填寫正確資料")
        return
      }
    }
    let data = {
      loanTarget: loanTarget,
      phoneNumb: phoneNumb,
      fullName: lastName + firstName,
      idCard: idCard,
      birthDate: birthDate,
      sex: sex,
      // nationality,
      amount,
      occupation: occupation,
      timestamp: moment().utcOffset(8).format("YYYY-MM-DD HH:mm:ss"),
      paymentTerms,
      liveAddress,
      liveBlock,
      liveFloor,
      liveMethod,
      liveRegion,
      liveUnit,
    }

    if (loan_selectID === "loan3") {
      data = {
        ...data,
        propertyAddress,
        propertyBlock,
        propertyFloor,
        propertyMethod,
        propertyMortageState,
        propertyRegion,
        propertyUnit,
      }
      if (sameToPropertyInfo) {
        data = {
          // ...data,
          // ...data.live,
          ...data,
          liveAddress: propertyAddress,
          liveBlock: propertyBlock,
          liveFloor: propertyFloor,
          liveMethod: propertyMethod,
          liveRegion: propertyRegion,
          liveUnit: propertyUnit,
        }
      }
    }

    if (loan_selectID === "loan1" || loan_selectID === "loan2") {
      data = {
        ...data,
        income,
        industry,
        payMethod,
        position,
        employment,
        occupation,
        companyAddress,
        companyName,
        companyPhone,
      }
    }
    if (loan_selectID === "loan4") {
      data = {
        ...data,
        carType,
        carBrand,
        carBrandFactory,
        carColor,
        carMile,
        carFirstMile,
        carLuxInfo,
        carModel,
        carTypeMode,
        carYear,
      }
    }

    const userData = {
      idCard: idCard,
      password: phoneNumb,
      displayName: lastName + firstName,
      loanNo: idCard,
    }
    // console.log(data)
    // return
    //create user after submit the enquiry form
    await createUser(userData)

    updateRecord(data)

    navigate("/thankyou")
  }

  const step1Pass = loanTarget && isSuccess
  const step2Pass = amount && paymentTerms
  // const step3Pass =
  //   lastName && idCard && firstName && sex && birthDate && nationality
  const step3Pass = lastName && idCard && firstName && sex && birthDate
  const step4Pass =
    loan_selectID === "loan3"
      ? agreeNext &&
        ((liveBlock &&
          liveMethod &&
          liveFloor &&
          liveUnit &&
          liveAddress &&
          liveRegion) ||
          sameToPropertyInfo) &&
        propertyBlock &&
        propertyMethod &&
        propertyFloor &&
        propertyUnit &&
        propertyAddress &&
        propertyRegion &&
        propertyMortageState
      : agreeNext
  const step5Pass =
    loan_selectID === "loan1" || loan_selectID === "loan2"
      ? income && employment && occupation && payMethod
      : loan_selectID === "loan4"
      ? carYear && carFirstMile && carMile
      : true

  return (
    <div className={`${css.container} apply-form`}>
      <div className="mb-5">
        <h1>{title}</h1>
        <h6>{title2}</h6>
      </div>

      {step === 1 && (
        <div className={css.step1_container}>
          <div className="mb-5">
            {/* <h5>請選擇您的貸款目的</h5> */}
            <h3>請選擇您的貸款目的</h3>
            <div
              className={[
                "d-flex flex-column justify-content-center",
                css.step1_buttonContainer,
              ].join(" ")}
            >
              <div className="d-flex">
                <div
                  id="loan1"
                  className={css.loanTarget}
                  onClick={(e) => {
                    setLoanTarget(loan_selectID === "loan1" ? null : "私人貸款")
                    setLoan_selectID(
                      loan_selectID === "loan1" ? "" : e.currentTarget.id
                    )
                  }}
                >
                  <img src={img1} alt="" />
                  <span className="mt-1">私人貸款</span>
                </div>
                <div
                  id="loan3"
                  className={css.loanTarget}
                  onClick={(e) => {
                    setLoanTarget(loan_selectID === "loan3" ? null : "業主貸款")
                    setLoan_selectID(
                      loan_selectID === "loan3" ? "" : e.currentTarget.id
                    )
                  }}
                >
                  <img src={img3} alt="" />
                  <span className="mt-1">業主貸款</span>
                </div>
              </div>
              <div className="d-flex">
                <div
                  id="loan2"
                  className={css.loanTarget}
                  onClick={(e) => {
                    setLoanTarget(loan_selectID === "loan2" ? null : "免TU貸款")
                    setLoan_selectID(
                      loan_selectID === "loan2" ? "" : e.currentTarget.id
                    )
                  }}
                >
                  <img src={img2} alt="" />
                  <span className="mt-1">免TU貸款</span>
                </div>

                <div
                  id="loan4"
                  className={css.loanTarget}
                  onClick={(e) => {
                    setLoanTarget(
                      loan_selectID === "loan4"
                        ? null
                        : "汽車及貴重物品抵押貸款"
                    )
                    setLoan_selectID(
                      loan_selectID === "loan4" ? "" : e.currentTarget.id
                    )
                  }}
                >
                  <img src={img4} alt="" />
                  <span className="mt-1 text-center">
                    汽車及貴重物品抵押貸款
                  </span>
                </div>
              </div>

              <Form.Group className="mb-3 mt-5">
                <Form.Label>
                  流動電話號碼<span className={css.requiredStar}>*</span>
                </Form.Label>
                <Form.Control
                  type="Text"
                  id="phoneNumb"
                  aria-describedby="phoneHelpBlock"
                  value={phoneNumb}
                  disabled={!loanTarget}
                  onChange={(e) => setPhoneNumb(e.target.value)}
                  style={{ width: "100%" }}
                />

                <Form.Text id="phoneHelpBlock" className="text-muted">
                  請提供有效流動電話號碼，以便接收密碼短訊
                </Form.Text>

                <br />
                {!phoneVerified && phoneNumb.length > 8 && (
                  <Form.Label className="alert-label alertcolor">
                    請輸入正確號碼
                  </Form.Label>
                )}
                <br />

                {expandForm && (
                  <Form.Control
                    type="otp"
                    placeholder="輸入驗證碼"
                    onChange={verifyOTP}
                    disabled={isSuccess}
                    style={{ width: "80%" }}
                  />
                )}
                {!expandForm && showsendBtn && (
                  <Button
                    className="mb-3 purple_btn"
                    onClick={requestOTP}
                    disabled={!phoneVerified}
                  >
                    送出驗證碼
                  </Button>
                )}

                {showRecaptcha && !redo && (
                  <div style={{ zIndex: 999 }} id="recaptcha-container"></div>
                )}

                <div className="d-flex flex-column">
                  {redo && (
                    <Button className="mb-3 purple_btn mt-2" onClick={restart}>
                      重新驗證
                    </Button>
                  )}

                  {isSentCode && !redo && (
                    <p className="msg-success ms-2">驗證碼已寄出</p>
                  )}
                </div>
                {error && (
                  <Alert
                    className="mt-2"
                    variant="danger"
                    style={{ width: "20%" }}
                  >
                    {error}
                  </Alert>
                )}
                {isSuccess && <p className="msg-success">✓ 電話號碼驗證成功</p>}
              </Form.Group>

              <NextStepBtn
                color={"color-orange"}
                disabled={!step1Pass}
                handle={step1Pass ? handleNextStep : null}
              />
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className={css.step2_container}>
          <h3>你的貸款計劃</h3>
          <Form.Group className="mb-3">
            <Form.Label>
              貸款金額<span className={css.requiredStar}>*</span>
            </Form.Label>
            <br />
            <CurrencyInput
              id="currency"
              name="currency"
              defaultValue={amount}
              decimalsLimit={2}
              prefix={"HK$ "}
              onValueChange={(value, name) => setAmount(value)}
              className="currency-input"
            />
            <br />
            <Form.Text className="text-muted">
              最高可申請額度為HK$ xxx,xxx
            </Form.Text>
          </Form.Group>
          <Form.Label>
            還款期<span className={css.requiredStar}>*</span>
          </Form.Label>
          <br />
          <div className={css.select_group}>
            <Button
              id="payment6"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment6" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment6" ? "" : e.currentTarget.id
                )
              }}
              value="6"
            >
              6
            </Button>
            <Button
              id="payment12"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment12" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment12" ? "" : e.currentTarget.id
                )
              }}
              value="12"
            >
              12
            </Button>
            <Button
              id="payment18"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment18" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment18" ? "" : e.currentTarget.id
                )
              }}
              value="18"
            >
              18
            </Button>
            <Button
              id="payment24"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment24" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment24" ? "" : e.currentTarget.id
                )
              }}
              value="24"
            >
              24
            </Button>
            <Button
              id="payment30"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment30" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment30" ? "" : e.currentTarget.id
                )
              }}
              value="30"
            >
              30
            </Button>
            <Button
              id="payment36"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment36" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment36" ? "" : e.currentTarget.id
                )
              }}
              value="36"
            >
              36
            </Button>
            <Button
              id="payment42"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment42" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment42" ? "" : e.currentTarget.id
                )
              }}
              value="42"
            >
              42
            </Button>
            <Button
              id="payment48"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment48" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment48" ? "" : e.currentTarget.id
                )
              }}
              value="48"
            >
              48
            </Button>
            <Button
              id="payment54"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment54" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment54" ? "" : e.currentTarget.id
                )
              }}
              value="54"
            >
              54
            </Button>
            <Button
              id="payment60"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment60" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment60" ? "" : e.currentTarget.id
                )
              }}
              value="60"
            >
              60
            </Button>
          </div>
          <div>
            <PrevStepBtn color={"color-orange"} handle={handlePrevStep} />
            <NextStepBtn
              color={"color-orange"}
              disabled={!step2Pass}
              handle={step2Pass ? handleNextStep : null}
            />
          </div>
        </div>
      )}

      {step === 3 && (
        <div className={css.step2_container}>
          <h3>填寫你的個人資料</h3>
          <div className="d-flex">
            <Form.Group className="mb-1">
              <Form.Label>
                {/* 姓氏<span className={css.requiredStar}>*</span> */}
                姓氏
              </Form.Label>
              <Form.Control
                type="Text"
                id="lastName"
                value={lastName}
                placeholder="例：CHAN"
                onChange={(e) => setLastName(e.target.value)}
                style={{ width: "80%" }}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>
                {/* 名稱<span className={css.requiredStar}>*</span> */}
                名稱
              </Form.Label>
              <Form.Control
                type="Text"
                id="firstName"
                value={firstName}
                placeholder="例：WEI"
                onChange={(e) => setFirstName(e.target.value)}
                style={{ width: "80%" }}
              />
            </Form.Group>
          </div>
          <Form.Text id="firstNamehelpBlock" className="text-muted">
            請輸入身份證明上的姓氏名稱。例如：Chan, Wei 即在姓氏上填
            <span className="color4">CHAN</span>, 名稱上填
            <span className="color4">Wei</span>
          </Form.Text>

          <Form.Group className="mb-3 mt-3">
            <Form.Label>
              {/* 香港身份證號碼<span className={css.requiredStar}>*</span> */}
              香港身份證號碼
            </Form.Label>
            <Form.Control
              type="Text"
              id="idCard"
              value={idCard}
              placeholder="Z 123456 (7)"
              onChange={(e) => setIdCard(e.target.value)}
              className={css.textbox}
            />
          </Form.Group>

          <Form.Group className="mb-3 mt-3">
            <Form.Label>
              {/* 出生日期<span className={css.requiredStar}>*</span> */}
              出生日期
            </Form.Label>
            <DatePicker
              value={birthDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              // maxDate={new Date()}
              maxDate={
                new Date(new Date().setFullYear(new Date().getFullYear() - 18))
              } // Set the max date to 18 years ago from today
              //   selected={startDate}
              placeholderText="YYYY / MM / DD"
              className="date-Picker"
              dateFormat="yyyy/MM/dd"
              onChange={(e) => handleDate(e)}
            />
          </Form.Group>

          <div className="mb-3 form_button">
            <Form.Label>
              {/* 性別<span className={css.requiredStar}>*</span> */}
              性別
            </Form.Label>
            <br />
            <div className={css.select_group}>
              <Button
                id="sex1"
                onClick={(e) => {
                  setSex(sex_selectID === "sex1" ? "" : e.target.value)
                  setSex_selectID(
                    sex_selectID === "sex1" ? "" : e.currentTarget.id
                  )
                }}
                value="男"
              >
                男
              </Button>
              <Button
                id="sex2"
                onClick={(e) => {
                  setSex(sex_selectID === "sex2" ? "" : e.target.value)
                  setSex_selectID(
                    sex_selectID === "sex2" ? "" : e.currentTarget.id
                  )
                }}
                value="女"
              >
                女
              </Button>
            </div>
          </div>
          {/* <Form.Group className="mb-3 mt-3">
            <Form.Label>
              國籍<span className={css.requiredStar}>*</span>
            </Form.Label>
            <Form.Control
              type="Text"
              id="nationality"
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
              className={css.textbox}

            />
          </Form.Group> */}
          <div className="mb-3">
            點擊"下一步",即表示我同意 Let's Go
            可以按隱私政策及收集個人資料聲明中所訂使用此頁面上的個人資料。
          </div>
          <div>
            <PrevStepBtn color={"color-orange"} handle={handlePrevStep} />
            <NextStepBtn
              color={"color-orange"}
              disabled={!step3Pass}
              handle={step3Pass ? handleNextStep : null}
            />
          </div>
        </div>
      )}
      {step === 4 && (
        <div className={css.step2_container}>
          <h3>
            {loan_selectID === "loan3"
              ? "填寫你的物業及住宅資料"
              : "填寫你的住宅資料"}
          </h3>
          {loan_selectID === "loan3" ? (
            <>
              <div className={css.subTitle}>物業資料</div>
              <div className="d-flex flex-row">
                <Form.Group className="mb-1">
                  <Form.Label>
                    單位<span className={css.requiredStar}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="Text"
                    id="propertyUnit"
                    value={propertyUnit}
                    onChange={(e) => setPropertyUnit(e.target.value)}
                    style={{ width: "80%" }}
                  />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>
                    樓層<span className={css.requiredStar}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="Text"
                    id="propertyFloor"
                    value={propertyFloor}
                    onChange={(e) => setPropertyFloor(e.target.value)}
                    style={{ width: "80%" }}
                  />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label>
                    座數<span className={css.requiredStar}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="propertyBlock"
                    value={propertyBlock}
                    onChange={(e) => setPropertyBlock(e.target.value)}
                    style={{ width: "80%" }}
                  />
                </Form.Group>
              </div>
              <Form.Group className="mb-3 mt-3">
                <Form.Label>
                  大廈/屋苑名稱／街道名稱
                  <span className={css.requiredStar}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="propertyAddress"
                  value={propertyAddress}
                  onChange={(e) => setPropertyAddress(e.target.value)}
                  style={{ width: "40%" }}
                />
              </Form.Group>
              <Form.Group className="mb-3 mt-3">
                <Form.Label>
                  地區<span className={css.requiredStar}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="propertyRegion"
                  value={propertyRegion}
                  onChange={(e) => setPropertyRegion(e.target.value)}
                  style={{ width: "40%" }}
                />
              </Form.Group>
              <div className="mb-3 form_button">
                <Form.Label>
                  居住情況<span className={css.requiredStar}>*</span>
                </Form.Label>
                <br />
                <div className={css.select_group}>
                  <Button
                    id="propertyMethodSelf"
                    onClick={(e) => {
                      setPropertyMethod(
                        propertyMethodID === "propertyMethodSelf"
                          ? ""
                          : e.target.value
                      )
                      setPropertyMethodID(
                        propertyMethodID === "propertyMethodSelf"
                          ? ""
                          : e.currentTarget.id
                      )
                    }}
                    value="自住"
                  >
                    自住
                  </Button>
                  <Button
                    id="propertyMethodRent"
                    onClick={(e) => {
                      setPropertyMethod(
                        propertyMethodID === "propertyMethodRent"
                          ? ""
                          : e.target.value
                      )
                      setPropertyMethodID(
                        propertyMethodID === "propertyMethodRent"
                          ? ""
                          : e.currentTarget.id
                      )
                    }}
                    value="放租"
                    disabled={false}
                  >
                    放租
                  </Button>
                </div>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    按揭銀行或財務公司情況
                    <span className={css.requiredStar}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="propertyMortageState"
                    value={propertyMortageState}
                    onChange={(e) => setPropertyMortageState(e.target.value)}
                    style={{ width: "40%" }}
                  />
                </Form.Group>
              </div>
            </>
          ) : null}
          <br />

          {loan_selectID === "loan3" ? (
            <>
              <div className={css.subTitle}>居住地址</div>
              <div className="mb-3">
                <FormControlLabel
                  control={<Checkbox checked={sameToPropertyInfo} />}
                  onChange={({ target }) => {
                    setSameToPropertyInfo(target.checked)
                  }}
                  label="同上"
                />
              </div>
            </>
          ) : null}
          <div className="d-flex flex-row">
            <Form.Group className="mb-1">
              <Form.Label>
                單位
                {loan_selectID === "loan3" ? (
                  <span className={css.requiredStar}>*</span>
                ) : null}
              </Form.Label>
              <Form.Control
                type="Text"
                id="liveUnit"
                disabled={sameToPropertyInfo}
                value={liveUnit}
                onChange={(e) => setLiveUnit(e.target.value)}
                style={{ width: "80%" }}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>
                樓層
                {loan_selectID === "loan3" ? (
                  <span className={css.requiredStar}>*</span>
                ) : null}
              </Form.Label>
              <Form.Control
                type="Text"
                disabled={sameToPropertyInfo}
                id="liveFloor"
                value={liveFloor}
                onChange={(e) => setLiveFloor(e.target.value)}
                style={{ width: "80%" }}
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>
                座數
                {loan_selectID === "loan3" ? (
                  <span className={css.requiredStar}>*</span>
                ) : null}
              </Form.Label>
              <Form.Control
                type="text"
                disabled={sameToPropertyInfo}
                id="liveBlock"
                value={liveBlock}
                onChange={(e) => setLiveBlock(e.target.value)}
                style={{ width: "80%" }}
              />
            </Form.Group>
          </div>
          <Form.Group className="mb-3 mt-3">
            <Form.Label>
              大廈/屋苑名稱／街道名稱
              {loan_selectID === "loan3" ? (
                <span className={css.requiredStar}>*</span>
              ) : null}
            </Form.Label>
            <Form.Control
              type="text"
              disabled={sameToPropertyInfo}
              id="liveAddress"
              value={liveAddress}
              onChange={(e) => setLiveAddress(e.target.value)}
              style={{ width: "40%" }}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3">
            <Form.Label>
              地區
              {loan_selectID === "loan3" ? (
                <span className={css.requiredStar}>*</span>
              ) : null}
            </Form.Label>
            <Form.Control
              type="text"
              disabled={sameToPropertyInfo}
              id="liveRegion"
              value={liveRegion}
              onChange={(e) => setLiveRegion(e.target.value)}
              style={{ width: "40%" }}
            />
          </Form.Group>
          <div className="mb-3 form_button">
            <Form.Label>
              居住情況
              {loan_selectID === "loan3" ? (
                <span className={css.requiredStar}>*</span>
              ) : null}
            </Form.Label>
            <br />

            {loan_selectID === "loan3" || loan_selectID === "loan4" ? (
              <ToggleBtnGroup
                ids={["liveMethodSelf", "liveMethodRent"]}
                values={["自住", "放租"]}
                defaultID=""
                disabled={sameToPropertyInfo}
                onToggle={(value) => {
                  setLiveMethod(value)
                }}
              />
            ) : (
              <Form.Control
                type="text"
                disabled={sameToPropertyInfo}
                id="liveMethod"
                value={liveMethod}
                onChange={(e) => setLiveMethod(e.target.value)}
                style={{ width: "40%" }}
              />
            )}
          </div>
          <div className="mb-3">
            <FormControlLabel
              control={<Checkbox checked={agreeNext} />}
              onChange={({ target }) => {
                setAgreeNext(target.checked)
              }}
              label="我同意 Let's Go 可以按私隱政策及收集個人資料聲明中所訂使用「個人資料」頁面及此頁面上的資料。"
            />
          </div>
          <div>
            <PrevStepBtn color={"color-orange"} handle={handlePrevStep} />
            {loan_selectID === "loan3" ? (
              <SubmitBtnBig
                color={"color-orange"}
                disabled={!step4Pass}
                handle={step4Pass ? handleSubmit : null}
              />
            ) : (
              <NextStepBtn
                color={"color-orange"}
                disabled={!step4Pass}
                handle={step4Pass ? handleNextStep : null}
              />
            )}
          </div>
        </div>
      )}

      {step === 5 && loan_selectID !== "loan3" && (
        <div className={css.step3_container}>
          {loan_selectID === "loan1" || loan_selectID === "loan2" ? (
            <>
              <h3>填寫你的工作資料</h3>
              <br />

              <div className="mb-3 form_button">
                <Form.Label>
                  就業情況<span className={css.requiredStar}>*</span>
                </Form.Label>
                <br />
                <div className={css.select_group}>
                  <Button
                    id="ftEmployee"
                    onClick={(e) => {
                      setOccupation(
                        occ_selectID === "ftEmployee" ? "" : e.target.value
                      )
                      setOcc_selectID(
                        occ_selectID === "ftEmployee" ? "" : e.currentTarget.id
                      )
                    }}
                    value="受僱（固定）"
                  >
                    受僱（固定）
                  </Button>
                  <Button
                    id="ptEmployee"
                    onClick={(e) => {
                      setOccupation(
                        occ_selectID === "ptEmployee" ? "" : e.target.value
                      )
                      setOcc_selectID(
                        occ_selectID === "ptEmployee" ? "" : e.currentTarget.id
                      )
                    }}
                    value="受僱（非固定）"
                  >
                    受僱（非固定）
                  </Button>
                  <Button
                    id="freelancer"
                    onClick={(e) => {
                      setOccupation(
                        occ_selectID === "freelancer" ? "" : e.target.value
                      )
                      setOcc_selectID(
                        occ_selectID === "freelancer" ? "" : e.currentTarget.id
                      )
                    }}
                    value="自僱"
                  >
                    自僱
                  </Button>
                  {/* <Button
                    id="occ4"
                    onClick={(e) => {
                      setOccupation(e.target.value)
                      setOcc_selectID(e.currentTarget.id)
                    }}
                    value="兼職"
                  >
                    兼職
                  </Button>
                  <Button
                    id="occ5"
                    onClick={(e) => {
                      setOccupation(e.target.value)
                      setOcc_selectID(e.currentTarget.id)
                    }}
                    value="學生"
                  >
                    學生
                  </Button>
                  <Button
                    id="occ6"
                    onClick={(e) => {
                      setOccupation(e.target.value)
                      setOcc_selectID(e.currentTarget.id)
                    }}
                    value="自由工作者"
                  >
                    自由工作者
                  </Button> */}
                </div>
              </div>
              <Form.Group className="mb-3">
                <Form.Label>公司名稱</Form.Label>
                <Form.Control
                  type="Text"
                  id="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className={css.textbox}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>公司地址</Form.Label>
                <Form.Control
                  type="Text"
                  id="companyAddress"
                  value={companyAddress}
                  placeholder="請填寫英文地址。若沒有固定辦公地點，請填寫Ｎ/Ａ。"
                  onChange={(e) => setCompanyAddress(e.target.value)}
                  className={css.textbox}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>辦公室電話號碼</Form.Label>
                <Form.Control
                  type="Text"
                  id="companyPhone"
                  value={companyPhone}
                  onChange={(e) => setCompanyPhone(e.target.value)}
                  className={css.textbox}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  每月平均收入<span className={css.requiredStar}>*</span>
                </Form.Label>
                <br />
                <CurrencyInput
                  id="income"
                  name="income"
                  defaultValue={income}
                  decimalsLimit={2}
                  prefix={"HK$ "}
                  onValueChange={(value, name) => setIncome(value)}
                  className="currency-input"
                />
              </Form.Group>

              <div className="mb-3 form_button">
                <Form.Label>
                  出糧方式<span className={css.requiredStar}>*</span>
                </Form.Label>
                <br />
                <div className={css.select_group}>
                  <Button
                    id="pay1"
                    onClick={(e) => {
                      setPayMethod(
                        pay_selectID === "pay1" ? "" : e.target.value
                      )
                      setPay_selectID(
                        pay_selectID === "pay1" ? "" : e.currentTarget.id
                      )
                    }}
                    value="自動轉賬"
                  >
                    自動轉賬
                  </Button>
                  <Button
                    id="pay2"
                    onClick={(e) => {
                      setPayMethod(
                        pay_selectID === "pay2" ? "" : e.target.value
                      )
                      setPay_selectID(
                        pay_selectID === "pay2" ? "" : e.currentTarget.id
                      )
                    }}
                    value="支票"
                  >
                    支票
                  </Button>
                  <Button
                    id="pay3"
                    onClick={(e) => {
                      setPayMethod(
                        pay_selectID === "pay3" ? "" : e.target.value
                      )
                      setPay_selectID(
                        pay_selectID === "pay3" ? "" : e.currentTarget.id
                      )
                    }}
                    value="現金"
                  >
                    現金
                  </Button>
                </div>
              </div>
              <div className="mb-3 form_button">
                <Form.Label>行業分類</Form.Label>
                <br />
                <div className={css.select_custom_container}>
                  <select
                    className={css.select_custom}
                    name="industry"
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                  >
                    <option value="bank">銀行及金融業</option>
                    <option value="constraction">建造業 / 裝修</option>
                    <option value="newpaper">報紙 / 雜誌 / 傳媒</option>
                    <option value="jclud">賽馬會 / 私人俱樂部</option>
                    <option value="sport">體育 / 文化藝術 / 娛樂</option>
                    <option value="education">教育業</option>
                    <option value="hotel">酒店 / 旅館業</option>
                    <option value="consultation">專業服務 / 顧問</option>
                    <option value="rent">租賃 / 其他商業服務</option>
                    <option value="publicMangement">公用事業(管理)</option>
                    <option value="publicSuppiler">公用事業(供應)</option>
                    <option value="warehouse">倉庫服務</option>
                    <option value="tour">旅遊業</option>
                    <option value="manufacture">製造 / 電子業</option>
                    <option value="publish">印刷 / 出版業</option>
                    <option value="social">醫護服務 / 社會服務業</option>
                    <option value="govPri">政府部門(紀律部隊)</option>
                    <option value="gov">政府部門</option>
                    <option value="nonGov">非政府組織</option>
                    <option value="property">房地產 / 物業代理</option>
                    <option value="dining">餐飲業</option>
                    <option value="cleaning">清潔</option>
                    <option value="hair">理髮 / 健康 / 美容業</option>
                    <option value="personal">個人 / 其他服務</option>
                    <option value="transport">運輸業</option>
                    <option value="mrigation">搬運 / 物流 / 速遞</option>
                    <option value="retail">批發 / 零售業</option>
                    <option value="other">其他</option>
                  </select>
                </div>
              </div>

              <Form.Group className="mb-3">
                <Form.Label>職位</Form.Label>
                <Form.Control
                  type="Text"
                  id="position"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  className={css.textbox}
                />
              </Form.Group>

              <div className="mb-3 form_button">
                <Form.Label>
                  受僱年期<span className={css.requiredStar}>*</span>
                </Form.Label>
                <br />
                <div className={css.select_group}>
                  <Button
                    id="lessYearEmployment"
                    onClick={(e) => {
                      setEmployment(
                        employmentID === "lessYearEmployment"
                          ? ""
                          : e.target.value
                      )
                      setEmploymentID(
                        employmentID === "lessYearEmployment"
                          ? ""
                          : e.currentTarget.id
                      )
                    }}
                    value="1年以下"
                  >
                    1年以下
                  </Button>
                  <Button
                    id="oneToThreeEmployment"
                    onClick={(e) => {
                      setEmployment(
                        employmentID === "oneToThreeEmployment"
                          ? ""
                          : e.target.value
                      )
                      setEmploymentID(
                        employmentID === "oneToThreeEmployment"
                          ? ""
                          : e.currentTarget.id
                      )
                    }}
                    value="1－3年"
                  >
                    1－3年
                  </Button>
                  <Button
                    id="overThreeEmployment"
                    onClick={(e) => {
                      setEmployment(
                        employmentID === "overThreeEmployment"
                          ? ""
                          : e.target.value
                      )
                      setEmploymentID(
                        employmentID === "overThreeEmployment"
                          ? ""
                          : e.currentTarget.id
                      )
                    }}
                    value="3年以上"
                  >
                    3年以上
                  </Button>
                </div>
              </div>

              <div className="d-flex">
                <PrevStepBtn color={"color-orange"} handle={handlePrevStep} />
                <SubmitBtnBig
                  color={"color-orange"}
                  disabled={!step5Pass}
                  handle={step5Pass ? handleSubmit : null}
                />
              </div>
            </>
          ) : loan_selectID === "loan4" ? (
            <>
              <h3>填寫你的抵押品資料</h3>
              <br />
              <div className={css.subTitle}>車輛資料</div>
              <div className="mb-3 form_button">
                <Form.Label>車輛類別</Form.Label>
                <br />
                <div className={css.select_group}>
                  <Button
                    id="privateCar"
                    onClick={(e) => {
                      setCarType(
                        carTypeID === "privateCar" ? "" : e.target.value
                      )
                      setCarTypeID(
                        carTypeID === "privateCar" ? "" : e.currentTarget.id
                      )
                    }}
                    value="私家車"
                  >
                    私家車
                  </Button>
                  <Button
                    id="vanCar"
                    onClick={(e) => {
                      setCarType(carTypeID === "vanCar" ? "" : e.target.value)
                      setCarTypeID(
                        carTypeID === "vanCar" ? "" : e.currentTarget.id
                      )
                    }}
                    value="客貨車"
                  >
                    客貨車
                  </Button>
                  <Button
                    id="itemCar"
                    onClick={(e) => {
                      setCarType(carTypeID === "itemCar" ? "" : e.target.value)
                      setCarTypeID(
                        carTypeID === "itemCar" ? "" : e.currentTarget.id
                      )
                    }}
                    value="貨車或電單車"
                  >
                    貨車或電單車
                  </Button>
                </div>
              </div>
              <Form.Group className="mb-3">
                <Form.Label>廠名</Form.Label>
                <Form.Control
                  type="Text"
                  id="carBrandfactory"
                  value={carBrandFactory}
                  onChange={(e) => setCarBrandFactory(e.target.value)}
                  className={css.textbox}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>型號</Form.Label>
                <Form.Control
                  type="Text"
                  id="carModel"
                  value={carModel}
                  onChange={(e) => setCarModel(e.target.value)}
                  className={css.textbox}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>顏色</Form.Label>
                <Form.Control
                  type="Text"
                  id="carColor"
                  value={carColor}
                  onChange={(e) => setCarColor(e.target.value)}
                  className={css.textbox}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>出廠年份</Form.Label>
                <Form.Control
                  type="Text"
                  id="carYear"
                  value={carYear}
                  onChange={(e) => setCarYear(e.target.value)}
                  className={css.textbox}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>首數</Form.Label>
                <Form.Control
                  type="Text"
                  id="carFirstMile"
                  value={carFirstMile}
                  onChange={(e) => setCarFirstMile(e.target.value)}
                  className={css.textbox}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>汽車公里數</Form.Label>
                <Form.Control
                  type="Text"
                  id="carMile"
                  value={carMile}
                  onChange={(e) => setCarMile(e.target.value)}
                  className={css.textbox}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>貴重品資料</Form.Label>
                <Form.Control
                  type="Text"
                  id="carLuxInfo"
                  value={carLuxInfo}
                  onChange={(e) => setCarLuxInfo(e.target.value)}
                  className={css.textbox}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>品牌</Form.Label>
                <Form.Control
                  type="Text"
                  id="carBrand"
                  value={carBrand}
                  onChange={(e) => setCarBrand(e.target.value)}
                  className={css.textbox}
                />
              </Form.Group>
              {/* <Form.Group className="mb-3">
                <Form.Label>型號</Form.Label>
                <Form.Control
                  type="Text"
                  id="carModel"
                  value={carModel}
                  onChange={(e) => setCarModel(e.target.value)}
                  className={css.textbox}

                />
              </Form.Group> */}
              {/* <Form.Group className="mb-3">
                <Form.Label>顏色</Form.Label>
                <Form.Control
                  type="Text"
                  id="carColor"
                  value={carColor}
                  onChange={(e) => setCarColor(e.target.value)}
                  className={css.textbox}

                />
              </Form.Group> */}
              <div className="mb-3 form_button">
                <Form.Label>全新或二手</Form.Label>
                <br />
                <div className={css.select_group}>
                  <Button
                    id="firstHand"
                    onClick={(e) => {
                      setCarTypeMode(
                        carTypeModeID === "firstHand" ? "" : e.target.value
                      )
                      setCarTypeModeID(
                        carTypeModeID === "firstHand" ? "" : e.currentTarget.id
                      )
                    }}
                    value="全新"
                  >
                    全新
                  </Button>
                  <Button
                    id="secondHand"
                    onClick={(e) => {
                      setCarTypeMode(
                        carTypeModeID === "secondHand" ? "" : e.target.value
                      )
                      setCarTypeModeID(
                        carTypeModeID === "secondHand" ? "" : e.currentTarget.id
                      )
                    }}
                    value="二手"
                  >
                    二手
                  </Button>
                </div>
              </div>
              <div className="d-flex">
                <PrevStepBtn color={"color-orange"} handle={handlePrevStep} />
                <SubmitBtnBig
                  color={"color-orange"}
                  disabled={!step5Pass}
                  handle={step5Pass ? handleSubmit : null}
                />
              </div>
            </>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default ApplicationForm
