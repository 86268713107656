import "./css/loanProduct.css"
import banner from "../images/privateloan/banner.jpg"
import banner_tablet768 from "../images/privateloan/banner_tablet768.png"
import banner_mobile425 from "../images/privateloan/banner_mobile425.png"
import stepsImage from "../images/privateloan/application_procedure.jpg"
import { useEffect } from "react"
import ImageDesc from "../compoients/ImageDesc"
import "./css/privateLoan.css"
import img1 from "../images/privateloan/point1.png"
import img2 from "../images/privateloan/point2.png"
import img3 from "../images/privateloan/point3.png"
import img4 from "../images/privateloan/point4.png"
import { ApplyBtnBig } from "../compoients/Btn"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"

const PrivateLoan = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 960px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 961px)" })
  const isMobile_small = useMediaQuery({ query: "(max-width: 600px)" })
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])
  const navigate = useNavigate()
  const backgroundImage = isMobile_small
    ? banner_mobile425
    : isTablet
    ? banner_tablet768
    : banner

  return (
    <div className="loanProduct-container">
      <div
        className="loanProduct-banner"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className="loanProduct-banner-text">
          <h1>私人貸款</h1>
          <hr className="loanProduct-banner-divider" />
          <p>AI分析大數據, 緊貼人工智能化時代</p>
          <p>以簡便手續及照顧個人隱私為優先考慮</p>
          <p>及時提供現金需要, 運籌帷幄</p>
        </div>
      </div>
      <div className="loanProduct-content">
        <div className="privateLoan-container">
          <div className="imageDesc-container">
            <ImageDesc
              image={img1}
              title={"人工智能化申請"}
              desc={
                "只需身份證及銀行戶口網上申請，網上批核，全程不經人手15分鐘合理報價，30分鐘現金到手"
              }
            />
            <ImageDesc
              image={img2}
              title={"創新級人工智能化審批"}
              desc={
                "申請全面智能化，無需五花八門繁瑣文件以加快批核速度為優先，批審方向會盡量滿足客人需求，提供一個貼心既報價，務求客人得到一個更快更舒適的資金支援"
              }
            />
          </div>
          <div className="imageDesc-container">
            <ImageDesc
              image={img3}
              title={"全面保障您的個人私隱"}
              desc={
                "申請程序完全不經人手，以滅低資料洩露風險，保護客人個人及財務隱私為主要考量，如客人有特別需要亦能同時提供專人回答個別貸款問題"
              }
            />
            <ImageDesc
              image={img4}
              title={"承諾即時處理貸款申請"}
              desc={
                "智能化系統為你準確報價，貸款額100萬以內，保證即日審批，即日過數，現金輕鬆到手，為你處理財務疑難"
              }
            />
          </div>
          <div className="mt-5">
            <ApplyBtnBig color={"#632e8d"} handle={() => navigate("/apply")} />
          </div>
          <div className="loanProduct-3steps">
            <img src={stepsImage} alt="只需要3個簡單步驟，即可輕鬆獲取貸款" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivateLoan
