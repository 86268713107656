import React, { useState, useRef } from "react"
import { PDFDocument } from "pdf-lib"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import fontkit from "@pdf-lib/fontkit"
import { Spinner } from "gestalt"
import styles from "../../css/UI.module.css"

const EditContract = ({ setModifiedPdf }) => {
  const [hasUpdated, setHasUpdated] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const [pdfUrl, setPdfUrl] = useState("/contract_template.pdf") // Initial relative path to the PDF
  const pdfRef = useRef(null) // Ref to download the modified PDF

  const [loanNo, setLoanNo] = useState("C10597")
  const [loanAgreementDate, setLoanAgreementDate] = useState("27 Jul 2023")
  const [maturityDate, setMaturityDate] = useState("23 Jan 2024")
  const [moneyLenderLicenceNumber, setMoneyLenderLicenceNumber] =
    useState("1173/2022")
  const [borrowerName, setBorrowerName] = useState("DAVID BECKHAM")
  const [hkid, setHkid] = useState("XD822657(8)")
  const [borrowerAddress, setBorrowerAddress] = useState(
    "屯門青山醫院, 第7座, 8樓, 5號房, 6號床位"
  )
  const [principalAmount, setPrincipalAmount] = useState("30,000.00")
  const [interestRate, setInterestRate] = useState("48%")
  const [firstInstalment, setFirstInstalment] = useState("5,723.00")
  const [firstDueDate, setFirstDueDate] = useState("26 Aug 2023")
  const [dueDateFrom, setDueDateFrom] = useState("25 Sep 2023")
  const [dueDateTo, setDueDateTo] = useState("24 Dec 2023")
  const [lastDueDate, setLastDueDate] = useState("23 Jan 2024")
  const [bankName, setBankName] = useState("THE BANK OF EAST ASIA LIMITED")
  const [accountNumber, setAccountNumber] = useState("000-123456-789")
  const [instalmentsPeriods, setInstalmentsPeriods] = useState("6")
  const [repayCycle, setRepayCycle] = useState("30")
  const [signedDate, setSignedDate] = useState("24JUL2023")
  const [moneyLendingLicenseNumber, setMoneyLendingLicenseNumber] =
    useState("1173/2022")
  const [totalAmoutInterest, setTotalAmoutInterest] = useState("4338")
  const [page6Date, setPage6Date] = useState("27 July 2023")

  // const [tenors, setTenors] = useState(Array(12).fill("24 December 2023"))
  const [tenor1, setTenor1] = useState("24 December 2023")
  const [tenor2, setTenor2] = useState("24 December 2023")
  const [tenor3, setTenor3] = useState("24 December 2023")
  const [tenor4, setTenor4] = useState("24 December 2023")
  const [tenor5, setTenor5] = useState("24 December 2023")
  const [tenor6, setTenor6] = useState("24 December 2023")
  const [tenor7, setTenor7] = useState("24 December 2023")
  const [tenor8, setTenor8] = useState("24 December 2023")
  const [tenor9, setTenor9] = useState("24 December 2023")
  const [tenor10, setTenor10] = useState("24 December 2023")
  const [tenor11, setTenor11] = useState("24 December 2023")
  const [tenor12, setTenor12] = useState("24 December 2023")

  const resetPDF = () => {
    setIsProcessing(true)
    setPdfUrl("/contract_template.pdf")
    setHasUpdated(false) // Reset hasUpdated to false
    setIsProcessing(false)
  }

  const handleUpdate = async () => {
    setIsProcessing(true)
    const fontBytes = await fetch("/NotoSansTC-Regular.ttf").then((res) =>
      res.arrayBuffer()
    )
    // Load the existing PDF
    const existingPdfBytes = await fetch(pdfUrl).then((res) =>
      res.arrayBuffer()
    )

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    pdfDoc.registerFontkit(fontkit)

    const customFont = await pdfDoc.embedFont(fontBytes)

    // Modify the PDF based on user input
    const pages = pdfDoc.getPages()
    const Page1 = pages[0]
    const Page2 = pages[1]
    const Page3 = pages[2]
    const Page4 = pages[3]
    const Page5 = pages[4]
    const Page6 = pages[5]
    Page1.drawText(loanNo, { x: 510, y: 750.5, size: 7 })
    Page1.drawText(loanAgreementDate, { x: 400, y: 706, size: 7 })
    Page1.drawText(loanAgreementDate, { x: 400, y: 696, size: 7 })
    Page1.drawText(maturityDate, { x: 400, y: 686, size: 7 })
    Page1.drawText(moneyLenderLicenceNumber, { x: 500, y: 672, size: 7 })
    Page1.drawText(borrowerName, {
      x: borrowerName.length > 15 ? 153 : 193,
      y: 633,
      size: 8,
      font: customFont,
    })
    Page1.drawText(hkid, { x: 400, y: 633, size: 7 })
    Page1.drawText(borrowerAddress, {
      x: 193,
      y: 615,
      size: 8,
      font: customFont,
    })
    Page1.drawText(principalAmount, {
      x: 193,
      y: 582.5,
      size: 7,
    })
    Page1.drawText(interestRate, {
      x: 497,
      y: 582.5,
      size: 7,
    })
    Page1.drawText(principalAmount, {
      x: 497,
      y: 552.5,
      size: 7,
    })
    Page1.drawText(principalAmount, {
      x: 497,
      y: 484,
      size: 7,
    })
    Page1.drawText(firstInstalment, {
      x: 230,
      y: 432.5,
      size: 7,
    })
    Page1.drawText(firstDueDate, {
      x: 478,
      y: 432.5,
      size: 7,
    })
    Page1.drawText(firstInstalment, {
      //second instalment
      x: 230,
      y: 408.5,
      size: 7,
    })
    Page1.drawText(dueDateFrom, {
      //due from
      x: 446,
      y: 408.5,
      size: 7,
    })
    Page1.drawText(dueDateTo, {
      //due to
      x: 508,
      y: 408.5,
      size: 7,
    })
    Page1.drawText(firstInstalment, {
      //last instalment
      x: 230,
      y: 383.5,
      size: 7,
    })
    Page1.drawText(lastDueDate, {
      //last due date
      x: 478,
      y: 383.5,
      size: 7,
    })
    Page1.drawText(bankName, {
      //bank name
      x: 230,
      y: 334.5,
      size: 8,
      font: customFont,
    })
    Page1.drawText(accountNumber, {
      //account number
      x: 230,
      y: 315,
      size: 7,
    })
    Page1.drawText(borrowerName, {
      //account holder
      x: 230,
      y: 300,
      size: 8,
      font: customFont,
    })
    Page1.drawText(borrowerName, {
      //name of payee
      x: 100,
      y: 256,
      size: 8,
      font: customFont,
    })
    Page1.drawText(principalAmount, {
      //amount
      x: 260,
      y: 257.5,
      size: 7,
    })

    // Repayment Schedule
    Page1.drawText(loanNo, { x: 143, y: 228.5, size: 7 })
    Page1.drawText(instalmentsPeriods, { x: 405, y: 228.5, size: 7 })
    Page1.drawText(principalAmount, { x: 151, y: 217.5, size: 7 })
    Page1.drawText(repayCycle, { x: 405, y: 216, size: 7 })

    Page1.drawText(tenor1, { x: 144, y: 187, size: 6.5 })
    Page1.drawText(tenor2, { x: 144, y: 178.5, size: 6.5 })
    Page1.drawText(tenor3, { x: 144, y: 170, size: 6.5 })
    Page1.drawText(tenor4, { x: 144, y: 161.5, size: 6.5 })
    Page1.drawText(tenor5, { x: 144, y: 152.5, size: 6.5 })
    Page1.drawText(tenor6, { x: 144, y: 144, size: 6.5 })
    Page1.drawText(tenor7, { x: 144, y: 135.5, size: 6.5 })
    Page1.drawText(tenor8, { x: 144, y: 127, size: 6.5 })
    Page1.drawText(tenor9, { x: 144, y: 118.5, size: 6.5 })
    Page1.drawText(tenor10, { x: 144, y: 109.5, size: 6.5 })
    Page1.drawText(tenor11, { x: 144, y: 100.5, size: 6.5 })
    Page1.drawText(tenor12, { x: 144, y: 92, size: 6.5 })

    //payment amount 1-12
    if (instalmentsPeriods) {
      const customYValues = [
        187.5, 178.5, 170, 161.5, 152.5, 144, 135.5, 127, 118, 109.5, 100.5, 92,
      ]
      for (let i = 0; i < instalmentsPeriods; i++) {
        const y = customYValues[i]
        Page1.drawText(firstInstalment, { x: 405, y: y, size: 6.5 })
      }
    }
    //Footer
    const drawTextOnPage = (page, pageNum) => {
      if (pageNum !== 5) {
        page.drawText(borrowerName, {
          x: 297,
          y: 63.3,
          size: 5.5,
          font: customFont,
        })
        page.drawText(hkid, {
          x: 400,
          y: 63.3,
          size: 5.5,
        })
        page.drawText(signedDate, {
          x: 96,
          y: 55.5,
          size: 5.5,
        })
        page.drawText(moneyLenderLicenceNumber, {
          x: 185,
          y: 48,
          size: 5.5,
        })
        page.drawText(signedDate, {
          x: 445,
          y: 48,
          size: 5.5,
        })
      } else {
        page.drawText(borrowerName, {
          x: 297,
          y: 59,
          size: 5.5,
          font: customFont,
        })
        page.drawText(hkid, {
          x: 400,
          y: 59,
          size: 5.5,
        })
        page.drawText(signedDate, {
          x: 96,
          y: 52,
          size: 5.5,
        })
        page.drawText(moneyLenderLicenceNumber, {
          x: 185,
          y: 44,
          size: 5.5,
        })
        page.drawText(signedDate, {
          x: 445,
          y: 44,
          size: 5.5,
        })
      }
    }

    for (let i = 0; i < 6; i++) {
      drawTextOnPage(pages[i], i + 1)
    }
    //loanNo
    Page2.drawText(loanNo, { x: 510, y: 750.5, size: 7 })
    Page3.drawText(loanNo, { x: 510, y: 750.5, size: 7 })
    Page4.drawText(loanNo, { x: 510, y: 750.5, size: 7 })
    Page5.drawText(loanNo, { x: 510, y: 750.5, size: 7 })
    Page6.drawText(loanNo, { x: 510, y: 750.5, size: 7 })

    //page 2
    Page2.drawText(firstInstalment.slice(0, -3), {
      x: 348,
      y: 414.7,
      size: 5.5,
    })
    Page2.drawText(firstInstalment.slice(0, -3), {
      x: 495,
      y: 414.7,
      size: 5.5,
    })
    Page2.drawText(firstInstalment.slice(0, -3), {
      x: 533,
      y: 414.7,
      size: 5.5,
    })
    Page2.drawText(instalmentsPeriods, {
      x: 522.5,
      y: 309.5,
      size: 5.5,
    })

    //page 3
    Page3.drawText(interestRate, {
      x: 131,
      y: 725,
      size: 5,
    })
    Page3.drawText(totalAmoutInterest, {
      x: 110,
      y: 717.5,
      size: 5.5,
    })

    //page 4
    Page4.drawText(interestRate, {
      x: 445,
      y: 286,
      size: 6,
    })
    Page4.drawText(totalAmoutInterest, {
      x: 459,
      y: 278.5,
      size: 6,
    })
    Page4.drawText(firstInstalment.slice(0, -3), {
      x: 460,
      y: 209.5,
      size: 6,
    })
    Page4.drawText(firstInstalment.slice(0, -3), {
      x: 393,
      y: 202,
      size: 6,
    })
    Page4.drawText(firstInstalment.slice(0, -3), {
      x: 435,
      y: 202,
      size: 6,
    })
    Page4.drawText(instalmentsPeriods, {
      x: 513,
      y: 132,
      size: 6,
    })
    //page 6
    Page6.drawText(borrowerName, {
      //Borrower
      x: 128,
      y: 127.5,
      size: 7,
      font: customFont,
    })
    Page6.drawText(page6Date, {
      x: 128,
      y: 119,
      size: 7,
    })

    // Serialize the modified PDF
    const modifiedPdfBytes = await pdfDoc.save()
    // setModifiedPdf(modifiedPdfBytes)

    // Convert it to a blob and update the state to re-render the iframe
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" })
    setModifiedPdf(modifiedPdfBytes)
    setPdfUrl(URL.createObjectURL(blob))
    setHasUpdated(true) // Set hasUpdated to true
    setIsProcessing(false)
  }

  return (
    <div className="contract-container">
      <div className="contract-input-container">
        <TextField
          label="貸款編號 Loan no."
          value={loanNo}
          onChange={(e) => setLoanNo(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="貸款協議立約日期 Loan Agreement Date"
          value={loanAgreementDate}
          onChange={(e) => setLoanAgreementDate(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="最後一期還款日 Maturity Date"
          value={maturityDate}
          onChange={(e) => setMaturityDate(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="放債人牌照號碼 Money Lender’s Licence Number"
          value={moneyLenderLicenceNumber}
          onChange={(e) => setMoneyLenderLicenceNumber(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="借款人 Name of Borrower"
          value={borrowerName}
          onChange={(e) => setBorrowerName(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="身份證號碼 HKID No."
          value={hkid}
          onChange={(e) => setHkid(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="借款人住址 Borrower Residential Address"
          value={borrowerAddress}
          onChange={(e) => setBorrowerAddress(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="貸款本金 Principal Amount of the Loan"
          value={principalAmount}
          onChange={(e) => setPrincipalAmount(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="利率 (每年) Interest rate (per annum)"
          value={interestRate}
          onChange={(e) => setInterestRate(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="首期還款金額 First Instalment"
          value={firstInstalment}
          onChange={(e) => setFirstInstalment(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="首個到期日 First Due Date"
          value={firstDueDate}
          onChange={(e) => setFirstDueDate(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="到期日 (開始) Due Date (from)"
          value={dueDateFrom}
          onChange={(e) => setDueDateFrom(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="到期日 (結束) Due Date (to)"
          value={dueDateTo}
          onChange={(e) => setDueDateTo(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="最後到期日 Last Due Date"
          value={lastDueDate}
          onChange={(e) => setLastDueDate(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="銀行名稱 Bank name"
          value={bankName}
          onChange={(e) => setBankName(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="賬戶號碼 Account number"
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <>
          <TextField
            label="期數1 Tenor1"
            value={tenor1}
            onChange={(e) => setTenor1(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="期數2 Tenor2"
            value={tenor2}
            onChange={(e) => setTenor2(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="期數3 Tenor3"
            value={tenor3}
            onChange={(e) => setTenor3(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="期數4 Tenor4"
            value={tenor4}
            onChange={(e) => setTenor4(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="期數5 Tenor5"
            value={tenor5}
            onChange={(e) => setTenor5(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="期數6 Tenor6"
            value={tenor6}
            onChange={(e) => setTenor6(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="期數7 Tenor7"
            value={tenor7}
            onChange={(e) => setTenor7(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="期數8 Tenor8"
            value={tenor8}
            onChange={(e) => setTenor8(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="期數9 Tenor9"
            value={tenor9}
            onChange={(e) => setTenor9(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="期數10 Tenor10"
            value={tenor10}
            onChange={(e) => setTenor10(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="期數11 Tenor11"
            value={tenor11}
            onChange={(e) => setTenor11(e.target.value)}
            fullWidth
            variant="outlined"
          />
          <TextField
            label="期數12 Tenor12"
            value={tenor12}
            onChange={(e) => setTenor12(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </>

        <TextField
          label="還款週期 Repay. Cycle"
          value={repayCycle}
          onChange={(e) => setRepayCycle(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="簽署日期 Signed Date"
          value={signedDate}
          onChange={(e) => setSignedDate(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="放債人牌照號碼 MoneyLendingLicensenumber"
          value={moneyLendingLicenseNumber}
          onChange={(e) => setMoneyLendingLicenseNumber(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="分期期數 Instalments Periods"
          value={instalmentsPeriods}
          onChange={(e) => setInstalmentsPeriods(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="貸款利息總額 The total amount of interest payable amount of interest payable"
          value={totalAmoutInterest}
          onChange={(e) => setTotalAmoutInterest(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          label="第六頁日期 Page 6 Date"
          value={page6Date}
          onChange={(e) => setPage6Date(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <div className="button-container">
          <Spinner show={isProcessing} accessibilityLabel="spinner" />
          <Button
            variant="contained"
            className={styles.signCanvas_button}
            onClick={handleUpdate}
            disabled={hasUpdated || isProcessing}
          >
            更新PDF
          </Button>
          <Button
            variant="contained"
            className={styles.signCanvas_button}
            onClick={resetPDF}
            disabled={isProcessing}
          >
            清除PDF
          </Button>
        </div>
      </div>
      <div className="contract-preview-container">
        <iframe
          className="contract-preview-iframe"
          src={pdfUrl}
          width={"90%"}
        ></iframe>
      </div>
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          className="mt-5"
          variant="contained"
          color="primary"
          ref={pdfRef}
          href={pdfUrl}
          download="modified_contract.pdf"
        >
          下載 PDF
        </Button>
      </div> */}
    </div>
  )
}

export default EditContract
