import { useRef, useCallback, useEffect, useState } from "react"
import Calculator from "../compoients/Calculator"
import css from "./css/home.module.css"

import "./css/home.css"
import EstimateForm from "../compoients/EstimateForm"
import { useMediaQuery } from "react-responsive"

import icon1 from "../images/home/icon1-1.png"
import icon2 from "../images/home/icon1-2.png"
import icon3 from "../images/home/icon1-3.png"
import icon4 from "../images/home/icon1-4.png"

import leftArrow from "../images/home/left-arrow.png"
import rightArrow from "../images/home/right-arrow.png"
import sample_slider_img from "../images/applyForm/lestbanner.png"

// import anim1 from "../images/home/anim1.gif"
// import anim2 from "../images/home/anim2.gif"
// import anim3 from "../images/home/anim3.gif"
// import anim4 from "../images/home/anim4.gif"
import peopleImg from "../images/home/part1-people.png"
import people3Img from "../images/home/part3-people.png"
import estimateForm_img from "../images/home/part4-people.png"

import { Modal, Box, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// import banner_outerframe from "../images/home/bannerbg.png"
// import tower from "../images/home/mobile/tower.png"
import image1 from "../images/home/in.png"
import image2 from "../images/blog/young-girl.jpg"
import slogan_bg from "../images/home/bannerbg.png"
import section1Text from "../images/home/homesection1text.png"
import outflame from "../images/home/homeBannerOuterframe.png"
import calculator_bg from "../images/home/Calculatorbg.png"
import { GoBtn } from "../compoients/Btn"
import { useNavigate } from "react-router-dom"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useFetch from "../hooks/useFetch"
import { ENV } from "../config"

// import { Center_ApplyBtn } from "../compoients/Btn"
// import TopMenu from "../compoients/TopMenu"

const IconBlock = ({ title, icon, desc }) => {
  return (
    <div className="d-flex flex-row">
      <div className={css.iconBlockImage}>
        <img src={icon} alt={title} />
      </div>
      <div className={["d-flex flex-column", css.iconBlockContent].join(" ")}>
        <h3>{title}</h3>
        <div>{desc}</div>
      </div>
    </div>
  )
}
const NextArrowComponent = ({ onClick }) => {
  return (
    <div className="slider-next-btn" onClick={onClick}>
      <img src={rightArrow} />
    </div>
  )
}

const PrevArrowComponent = ({ onClick }) => {
  return (
    <div className="slider-prev-btn" onClick={onClick}>
      <img src={leftArrow} />
    </div>
  )
}

const Home = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" })
  const isMobile_toogle = useMediaQuery({ query: "(max-width: 990px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 991px)" })

  const isBreakpoint = useMediaQuery({ minWidth: 501, maxWidth: 990 })
  const navigate = useNavigate()

  const bannerData = useFetch(ENV + "/api/v1/banner")
  const [topBannerList, setTopBannerList] = useState([])
  const [sliderList, setSliderList] = useState([])

  /*video*/

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isDesktop ? "70vw" : "90vw", // Set the width of the video or make it responsive
    // bgcolor: "background.paper",
    border: "none",
    outline: "none",
    boxShadow: 24,
  }
  const [open, setOpen] = useState(false)
  const videoRef = useRef(null)

  // Play the video as soon as the page loads
  useEffect(() => {
    setOpen(true)
  }, [])

  const handleClose = () => {
    setOpen(false)
  }
  /* end video*/

  useEffect(() => {
    if (bannerData && bannerData.data) {
      const blogBanners = bannerData.data.filter(
        (item) => item.location === "homePromote"
      )
      setSliderList(blogBanners)

      const homeBanners = bannerData.data.filter(
        (item) => item.location === "home"
      )
      setTopBannerList(homeBanners)
    }
  }, [bannerData])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  const settings = {
    className: css.slider_container,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    // nextArrow: <YourNextArrowComponent />,
    // prevArrow: <YourPrevArrowComponent />,
  }

  const homeBannerSettings = {
    className: css.homeBanner_slider_container,
    fade: true,
    arrows: false,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    // nextArrow: <YourNextArrowComponent />,
    // prevArrow: <YourPrevArrowComponent />,
  }

  const homeBannerRef = useRef(null)
  const sliderRef = useRef(null)
  const goToNext = () => {
    // Assuming you have access to slider's ref
    sliderRef.current.slickNext()
  }

  const goToPrevious = () => {
    // Assuming you have access to slider's ref
    sliderRef.current.slickPrev()
  }

  const [pageYOffset, setPageYOffset] = useState(0)
  const imgGirlRef = useRef(null)
  const newSection2_upperTitle_Ref = useRef(null)
  const iconBlockContainerWrapper_Ref = useRef(null)
  const newSectionSlider_Ref = useRef(null)
  const newSection3_inner_container_left_Ref = useRef(null)
  const car_Ref = useRef(null)
  const estimateForm_Ref = useRef(null)
  const sectionLast_Ref = useRef(null)

  //mobile animations
  const section1_container_mobile_Ref = useRef(null)
  const section2_upperTitle_mobile_Ref = useRef(null)
  const peopleImg_mobile_Ref = useRef(null)
  const estimateForm_img_Ref = useRef(null)

  useEffect(() => {
    function handleScroll() {
      setPageYOffset(window.scrollY)
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (isDesktop) {
      if (pageYOffset >= 500) {
        imgGirlRef.current.classList.add("fade-right-element")
        newSection2_upperTitle_Ref.current.classList.add("fade-left-element")

        iconBlockContainerWrapper_Ref.current.classList.add("fade-left-element")
      }
      if (pageYOffset >= 1400) {
        newSectionSlider_Ref.current.classList.add("fade-up-element")
      }

      if (pageYOffset >= 2100) {
        newSection3_inner_container_left_Ref.current.classList.add(
          "fade-right-element"
        )
        car_Ref.current.classList.add("fade-left-element")
      }
      if (pageYOffset >= 2800) {
        estimateForm_Ref.current.classList.add("fade-up-element")
      }

      if (pageYOffset >= 3200) {
        sectionLast_Ref.current.classList.add("fade-in-element")
      }
    }
    if (isMobile || isMobile_toogle) {
      section1_container_mobile_Ref.current.classList.add("fade-up-element")
      if (pageYOffset >= 500) {
        section2_upperTitle_mobile_Ref.current.classList.add("fade-up-element")
        peopleImg_mobile_Ref.current.classList.add("fade-up-element")
      }
      if (pageYOffset >= 1100) {
        newSectionSlider_Ref.current.classList.add("fade-up-element")
      }
      if (pageYOffset >= 1900) {
        newSection3_inner_container_left_Ref.current.classList.add(
          "fade-up-element"
        )
        car_Ref.current.classList.add("fade-up-element")
      }

      if (pageYOffset >= 2900) {
        estimateForm_img_Ref.current.classList.add("fade-in-element")
        estimateForm_Ref.current.classList.add("fade-in-element")
      }
      if (pageYOffset >= 3800) {
        sectionLast_Ref.current.classList.add("fade-in-element")
      }
    }
  }, [pageYOffset])

  return (
    <div className="home">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: -28,
              top: -28,
              zIndex: 99,
            }}
          >
            <CloseIcon />
          </IconButton>
          <video
            id="vd"
            ref={videoRef}
            muted
            playsInline
            autoPlay={true}
            loop
            width="100%"
            controls={true}
            style={{ border: "none", outline: "none", marginBottom: "-5px" }}
          >
            <source src={"/intro.mp4"} type="video/mp4" />
          </video>
        </Box>
      </Modal>
      <div className={css.section1_section2_container}>
        <div className={css.section1}>
          <div className={`${css.container}`}>
            {isDesktop && (
              <div className={`${css.inner_container} fade-up-element`}>
                <div className={`${css.leftContainer}`}>
                  <img src={outflame} alt="" className={css.outerframe} />
                  <Slider ref={homeBannerRef} {...homeBannerSettings}>
                    {topBannerList?.map((item, idx) => (
                      <div key={idx} className={css.homeBanner_item}>
                        <img src={item.banner} alt={idx + item.title} />
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className={css.rightContainer}>
                  <div className={css.calculator_wrapper}>
                    <Calculator />
                  </div>
                </div>
              </div>
            )}

            {(isMobile || isBreakpoint) && (
              <div
                ref={section1_container_mobile_Ref}
                className={css.section1_container_mobile}
              >
                <img
                  src={section1Text}
                  alt="slogan"
                  className={css.homeSection1Text}
                />
                <div className={css.slogan_container_mobile}>
                  <img src={outflame} alt="" className={css.outerframe} />
                  <Slider ref={homeBannerRef} {...homeBannerSettings}>
                    {topBannerList?.map((item, idx) => (
                      <div key={idx} className={css.homeBanner_item}>
                        <img src={item.banner} alt={idx + item.title} />
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className={css.calculator_mobile_container}>
                  <img
                    src={calculator_bg}
                    alt=""
                    className={css.calculator_bg_mobile}
                  />
                  <div className={css.calculator_mobile}>
                    <Calculator />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isDesktop && (
          <div className={css.newSection2}>
            <div className={css.newSection2_inner_container}>
              <div className={css.newSection2_inner_container_left}>
                <img ref={imgGirlRef} src={peopleImg} />
              </div>

              <div className={css.newSection2_inner_container_right}>
                <div
                  ref={newSection2_upperTitle_Ref}
                  className={css.newSection2_upperTitle}
                >
                  <h2>私人貸款</h2>
                  <div>A ‧ I輕鬆自助批核</div>
                  <div>貸款額度高達$2,000,000</div>
                  <GoBtn
                    label={"了解let'sgo私人貸款"}
                    handle={() => navigate("/privateloan")}
                  />
                </div>

                <div
                  ref={iconBlockContainerWrapper_Ref}
                  className={[
                    "d-flex flex-column",
                    css.iconBlockContainerWrapper,
                  ].join(" ")}
                >
                  <div
                    className={["d-flex flex-row", css.iconBlockContainer].join(
                      " "
                    )}
                  >
                    <IconBlock
                      icon={icon1}
                      title={"最多可以借"}
                      desc={"港幣2,000,000"}
                    />
                    <IconBlock
                      icon={icon2}
                      title={"最耐可以借"}
                      desc={"6至72個月"}
                    />
                  </div>
                  <div
                    className={["d-flex flex-row", css.iconBlockContainer].join(
                      " "
                    )}
                  >
                    <IconBlock
                      icon={icon3}
                      title={"實際年利率"}
                      desc={"低至3.36%"}
                    />
                    <IconBlock
                      icon={icon4}
                      title={"由批核至現金到手"}
                      desc={"最快1小時內"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* mobile section2 */}
        {(isMobile || isBreakpoint) && (
          <div className={css.section2_container_mobile}>
            <div
              ref={section2_upperTitle_mobile_Ref}
              className={css.section2_upperTitle_mobile}
            >
              <h1>私人貸款</h1>
              <div>A ‧ I輕鬆自助批核</div>
              <div>貸款額度高達$2,000,000</div>
            </div>
            <div className={css.peopleImg_wrapper}>
              <img
                ref={peopleImg_mobile_Ref}
                src={peopleImg}
                className={css.peopleImg_mobile}
              />
            </div>
            <div className="pt-3 pb-5">
              <GoBtn
                label={"了解let'sgo私人貸款"}
                handle={() => navigate("/privateloan")}
              />
            </div>
          </div>
        )}

        <div ref={newSectionSlider_Ref} className={css.newSectionSlider}>
          <div className={css.newSectionSlider_left}>
            <div className={css.newSectionSlider_left_title}>
              <h1>
                最新動態及
                <br />
                推廣
              </h1>
              <div>隨時緊貼我哋最新動向和優惠</div>

              <div className={css.slider_arrow_wrapper}>
                <PrevArrowComponent onClick={goToPrevious} />
                <NextArrowComponent onClick={goToNext} />
              </div>
            </div>
          </div>

          <div className={css.newSectionSlider_right}>
            <Slider ref={sliderRef} {...settings}>
              {sliderList?.map((item, idx) => (
                <div key={idx} className={css.slider_item}>
                  <img
                    src={item.banner}
                    alt={idx + item.title}
                    onClick={() => (window.location.href = item.linkto)}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <div className={css.newSection3}>
          <div className={css.newSection3_inner_container}>
            <div
              ref={newSection3_inner_container_left_Ref}
              className={css.newSection3_inner_container_left}
            >
              <div className={css.newSection3_upperTitle}>
                <h2>抵押品貸款</h2>
                <div>借到百分百抵押品價畀你，財務</div>
                <div>安排更輕鬆自在！</div>
                <GoBtn
                  label={"了解let's go抵押品貸款"}
                  handle={() => navigate("/collateralLoan")}
                />
              </div>
              <div className={css.iconBlockHContainer}>
                <IconBlock
                  icon={icon1}
                  title={"貸款額"}
                  desc={"高達抵押品價值100%"}
                />
                <IconBlock
                  icon={icon2}
                  title={"實際年利率"}
                  desc={"低至3.36%"}
                />
                <IconBlock
                  icon={icon3}
                  title={"彈性還款期"}
                  desc={"12至72個月"}
                />
                <IconBlock
                  icon={icon4}
                  title={"由批核至現金到手"}
                  desc={"最快24小時內"}
                />
              </div>
            </div>
            <div className={css.newSection3_inner_container_right}>
              <img ref={car_Ref} src={people3Img} alt="car" />
            </div>
          </div>
        </div>
      </div>

      <div className={css.section3}>
        {(isMobile || isBreakpoint || isMobile_toogle) && (
          <div className={css.estimateForm_img_container}>
            <img
              ref={estimateForm_img_Ref}
              src={estimateForm_img}
              alt="estimateForm_img"
              className={css.estimateForm_img}
            />
          </div>
        )}
        <div ref={estimateForm_Ref} className={css.estimateForm_container}>
          <EstimateForm />
        </div>
      </div>
      <div className={css.sectionLast}>
        <div ref={sectionLast_Ref} style={{ opacity: 0 }}>
          1 放債人行業獨家。
          <br />
          # 申請者須符合指定要求，實際情況或會因個別情況而有所不同。
          <br />
          * 免入息證明及住址證明只適用於貸款額最高為
          HK$300,000，並符合特定信貸批核要求的客戶。按個別情況，Let’s Go
          信貸有權要求客戶提供其他證明文件作貸款審批用途。如客戶提供額外申請文件，則可申請更高貸款金額。為核實申請人身份及安排放款，新客戶仍須網上提交身份證及銀行戶口或提款卡副本。
          <br />
          **
          只適用於收到申請者的環聯信貸報告後計出報價的所需時間。申請者須符合指定要求，實際所需時間或會因個別情況而有所不同。
          <br />
          ^^
          網上確認貸款合約並完成最後資料核實及審批後，貸款即時以轉數快方式自動過戶，最後收款時間須按個別銀行安排而定。
          <br />
          *** 網上借貸/全 Online 借貸指申請貸款過程由 A.I.
          網上處理。貸款利息計算方法由 A.I.
          透過大數據處理。申請者須符合指定要求，實際情況或會因個別情況而有所不同。
          <br />
          Let’s Go 信貸擁有對貸款審批及任何爭議之最終決定權。
          <br />
          以上之條款及細則，如中、英文兩個版本有任何抵觸或不相符之處，應以英文版本為準。
        </div>
      </div>
    </div>
  )
}

export default Home
