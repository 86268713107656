import css from "./css/thankyou.module.css"
import bg from "../images/applyForm/bg.png"
import img1 from "../images/applyForm/fillin.png"
import { useEffect } from "react"

const Thankyou = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  return (
    <div className={css.container}>
      <div className={css.bg_container}>
        <img src={bg} alt="" className={css.bg} />
        <img src={img1} alt="" className={css.img1} />
        <div className={css.title}>
          <h5>歡迎閣下申請</h5>
          <h3>樂高信貸私人貸款</h3>
        </div>
      </div>
      <div className={css.form_container}>
        <div className={css.content}>
          <h2>恭喜閣下已成功遞交申請，我們會盡快通知您！</h2>
          <h2>
            或 WhatsApp：
            <a href="https://wa.me/85254124661" target="_blank">
              5412 4661
            </a>
            &nbsp; 同我地客戶經理即時對話啦！
          </h2>
        </div>
      </div>
    </div>
  )
}

export default Thankyou
