// import ReactChatbot from "./chatbot/ReactChatbot"
import "./css/floatBtnlist.css"
import FbBtn from "./btns/FbBtn"
import PhoneBtn from "./btns/PhoneBtn"
import WhatsAppBtn from "./btns/WhatsAppBtn"

const FloatBtnList = ({ data }) => {
  const phone = data?.phone?.find((item) => item.label === "phone")?.description
  const whatsappNum = data?.whatsapp?.find(
    (item) => item.label === "whatsapp"
  )?.description
  const fblink = data?.facebook?.find(
    (item) => item.label === "facebook"
  )?.description

  return (
    <div className="floatBtnList">
      <div className="floatBtnList-item">
        <PhoneBtn phone={phone} />
      </div>
      <div className="floatBtnList-item">
        <WhatsAppBtn phone={whatsappNum} />
      </div>
      <div className="floatBtnList-item">
        <FbBtn fblink={fblink} />
      </div>
      {/* <div className="floatBtnList-item">
        <ReactChatbot />
      </div> */}
    </div>
  )
}

export default FloatBtnList
