import "./css/loanProduct.css"
import banner from "../images/tu/banner.png"
import banner_mobile425 from "../images/tu/banner_mobile425.png"
import banner_tablet768 from "../images/tu/banner_tablet768.png"
import { useEffect } from "react"
import ImageDesc from "../compoients/ImageDesc"
import img1 from "../images/tu/tu1.png"
import img2 from "../images/tu/tu2.png"
import img3 from "../images/tu/tu3.png"
import "./css/tu.css"
import { ApplyBtnBig } from "../compoients/Btn"
import { FormDatatext } from "../hooks/useFormatDatatext"
import HorizontalTimeline from "../compoients/HorizontalTimline"
import stepsImage from "../images/privateloan/application_procedure.jpg"
import bottomBanner from "../images/tu/bottom_banner.jpg"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import { useState } from "react"

const Tu = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 960px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 961px)" })
  const isMobile_small = useMediaQuery({ query: "(max-width: 600px)" })
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])
  const navigate = useNavigate()
  const backgroundImage = isMobile_small
    ? banner_mobile425
    : isTablet
    ? banner_tablet768
    : banner

  const [bannerRight, setBannerRight] = useState("")
  useEffect(() => {
    if (backgroundImage !== banner_mobile425) {
      setBannerRight("5rem")
    } else {
      setBannerRight("8.3rem")
    }
  }, [backgroundImage])

  return (
    <div className="loanProduct-container">
      <div
        className="loanProduct-banner"
        style={{ backgroundImage: `url(${backgroundImage}` }}
      >
        <div className="loanProduct-banner-text" style={{ right: bannerRight }}>
          <h1>免TU貸款</h1>
          <hr class="loanProduct-banner-divider" />
          <p>彈性審視客人債務狀況</p>
          <p>去配合客人資金運用需求</p>
          <p>為你解決煩惱</p>
        </div>
      </div>
      <div className="loanProduct-content">
        <div className="tu-container">
          <ImageDesc
            image={img1}
            title={"無須提交入息證明或住址證明"}
            desc={
              "即日網上審批，無需露面亦可申請亦可選擇專人為你度身定做貸款內容各式其式，應有盡有"
            }
          />
          <ImageDesc
            image={img2}
            title={"全天侯7x24批核貸款申請"}
            desc={
              "無論何時何地申請保證每宗個案於最短時間內處理務求以最快的速度為客人提供現金支援"
            }
          />
          <ImageDesc
            image={img3}
            title={"人性化批核流程"}
            desc={
              "完全唔考慮客人信貸評級優劣為你提供更多現金支援渠道及選擇助你達成願望"
            }
          />
        </div>

        <div>
          <ApplyBtnBig color={"#632e8d"} handle={() => navigate("/apply")} />
        </div>
        {/* <div className="tu-section2 pt-5 pb-5">
          <div className="title-h1 color1 text-center">
            {FormDatatext("揀樂高信貸\n只需簡單步驟，即可極速完成貸款")}
          </div>
          <HorizontalTimeline />
          <div className="mt-5">
            <ApplyBtnBig
              classColor={"color-orange"}
              handle={() => navigate("/apply")}
            />
          </div>
        </div> */}
        <div className="loanProduct-3steps">
          <img src={stepsImage} alt="只需要3個簡單步驟，即可輕鬆獲取貸款" />
        </div>
        <div>
          <img
            src={bottomBanner}
            alt="bottombanner"
            className="bottom-banner"
          />
        </div>
      </div>
    </div>
  )
}

export default Tu
