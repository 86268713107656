import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"

import css from "./css/blogPostPage.module.css"
import useFetch from "../hooks/useFetch"
import { ENV } from "../config"
import { FormDatatext } from "../hooks/useFormatDatatext"

const BlogPostPage = () => {
  const { data: posts } = useFetch(ENV + "/api/v1/post")
  const { postId } = useParams()
  const [post, setPost] = useState(null)
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  useEffect(() => {
    if (posts) {
      const foundPost = posts.find((post) => post.title === postId)
      setPost(foundPost)
    }
  }, [posts, postId])

  const handleThumbnailClick = (clickedPostId) => {
    navigate(`/blog/${clickedPostId}`) // Navigate to the specific blog post page
    window.scrollTo({ top: 50, behavior: "smooth" })
  }

  if (!post) {
    return <div>請等待一下...</div>
  }

  return (
    <div className={`${css.blog_post_page} fade-in-element`}>
      <div className={`${css.left_side}`}>
        <h1 className="color1 pb-2">{post.title}</h1>
        <img src={post.banner} alt={post.title} />
        <p>{FormDatatext(post.content)}</p>
      </div>
      <div className={css.right_side}>
        <h2 className="color1">為你推薦</h2>
        <hr></hr>
        {posts.map((thumbnailPost) => (
          <div
            key={thumbnailPost._id}
            className={css.thumbnail}
            onClick={() => handleThumbnailClick(thumbnailPost.title)} // Pass the post ID to the click handler
          >
            <img src={thumbnailPost.thumbnail} alt={thumbnailPost.title} />
            <p>{thumbnailPost.title}</p>
            <p>{thumbnailPost.content.slice(0, 16)}...</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BlogPostPage
