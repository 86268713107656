export const data1 = [
  {
    order: 1,
    question: "Q : 如何申請Let's Go網上私人貸款？",
    answer:
      "A : 可於網站填寫簡單申請表格，2分鐘即可完成，15分鐘獲悉申請結果。或可致電5412 4661，會有專人提供協助。",
  },
  {
    order: 2,
    question: "Q : Let's go貸款申請需要前往門市嗎？",
    answer:
      "A : 不需要，全程網上可完成。只需填妥簡單表格及上載簡單文件，便可於網上簽署貸款文件，15分鐘FPS完成放款。",
  },
  {
    order: 3,
    question: "Q : 於Let's go 申請貸款需要甚麼條件嗎？",
    answer: "A : 必需為香港居民，只需提交簡單文件如身份證以作核對便可。",
  },
  {
    order: 4,
    question: "Q : Let's go貸款的審批時間大概為多久？",
    answer:
      "A : Let's go致力為眾多客戶瞬速解決財務問題，申請手續非常簡單快捷，上載文件後，最快15分鐘內完成批核。 請即點擊「立即申請」按鈕或「WhatsApp 查詢」按鈕與申請專員聯絡，謝謝！",
  },
  {
    order: 5,
    question: "Q : 如已提交貸款申請，內容可以更改嗎？",
    answer:
      "A : 可以，只需聯絡Let's go財務貸款專家，我們會為您解決一切貸款申請問題。",
  },
  {
    order: 6,
    question: "Q : 網上貸款申請途中，我的私隱會否外洩？",
    answer:
      "A : 不會。Let's go非常重視每一位客戶的私隱，所有提交的文件或資料絕對保密！",
  },
  {
    order: 7,
    question: "Q ： Let's go會否提供免TU貸款？",
    answer:
      "A : 會的，不少客戶希望申請貸款時不影響TU評級，我們亦樂於提交免TU貸款予客戶",
  },
  {
    order: 8,
    question: "Q : 貸款申請途中是否一定需要提供入息證明？",
    answer:
      "A : 不一定。Let's go貸款亦會惠及收入不穩客戶的客戶，即使未能提供入息證明，亦會接納閣下的貸款申請。",
  },
  {
    order: 9,
    question: "Q : Let's go貸款的還款期數最長為？",
    answer:
      "A : 最長可高達72期，為客戶提供較舒適的財務貸款方案，大幅舒緩閣下的財務壓力。",
  },
  {
    order: 10,
    question: "Q : Let's go貸款申請需要收取手續費嗎？",
    answer: "A : 我們的所有貸款方案是絕不收取任何手續費，全程可由網上處理",
  },
]

export const data2 = [
  {
    order: 1,
    question: "Q : 申請 LET’S GO私人貸款有手續費嗎?",
    answer: "A :  LET’S GO私人貸款絕無任何手續費。",
  },
  {
    order: 2,
    question: "Q : LET’S GO信貸提供甚麼類型的貸款服務？",
    answer: "A : 私人貸款、汽車貸款、業主貸款等等",
  },
  {
    order: 3,
    question: "Q : 私人貸款的貸款額有多少？還款期為？",
    answer:
      "A : 私人貸款的貸款額為HK$5,000至HK$600,000，可配合客戶製化的還款期，由3個月至24個月不等，LET’S GO 私人貸款的靈活彈性配合你的週轉需求。最高貸款金額是根據客戶個人信息，包含但不限於信貸評級、從事職業、每月收入、及財務狀況而釐定。",
  },
  {
    order: 4,
    question: "Q : 客戶可以指定繳款日期嗎？",
    answer:
      "A : 當貸款批核成功所簽署的合約為準，若撥款後，客戶欲變更繳款日期，請立即聯繫LET’S GO信貸服務專員。",
  },
  {
    order: 5,
    question: "Q : 貸款合約在甚麼情形下會縮短我的還款期限呢？",
    answer:
      "A : 借款人如果有任何一筆債務沒有依約定支付利息、費用、其他應付款項，本行不會事先通知或催告，就可以隨時對借款人收回部分借款、縮短借款期限、或視為全部到期。",
  },
]

export const data3 = [
  {
    order: 1,
    question: "Q : 提早償還私人分期貸款，是否可以節省利息開支?",
    answer:
      "A : 一般來説，客戶提早償還私人分期貸款可節省未償還的利息。但決定是否提前還款時，還應考慮涉及額外繳付的提早償還手續費用。精明的做法是客戶應先向本行查詢提前還款的總金額（包括尚欠的貸款餘額、提早償還貸款費用及其他的費用等）和未償還的利息金額，比較和考慮清楚後，才決定是否選擇提前還款。",
  },
  {
    order: 2,
    question: "Q : 提取私人分期貸款後還有什麼需要留意?",
    answer:
      "A : 提取私人分期貸款後，請緊記準時還款，以免被收取因逾期還款而引起的額外利息及費用。此外，逾期還款紀錄亦會記錄在個人信貸資料庫內，或會直接影響客戶日後其他貸款之申請。",
  },
  {
    order: 3,
    question: "Q : LET’S GO私人貸款接受甚麽還款方式？",
    answer: "A : 客戶可透過銀行戶口轉帳方式或親臨本行還款。",
  },
]

export const data4 = [
  {
    order: 1,
    question: "Q: 如果現在或曾經破產，還可以成功申請私人借貸嗎？",
    answer:
      "A: 私人貸款審批過程將全面分析你現時的財務狀況、包括月入穩定性、還款紀錄、信貸評分及其他資料。LET'S GO 亦會因應個別具體情況，再進一步評估，甚至豁免某些特定要求。",
  },
  {
    order: 2,
    question: "Q: LET'S GO 收取什麽費用？是否有借貸利息之外的任何隱性收費？",
    answer:
      "A: LET'S GO絕不會收取任何申請費、擔保費、 影印費或介紹費等隱性費用，而且手續費全免。所有申請，無論被取消、被拒絕或授予貸款，只要最終無接納貸款，都不會有任何收費，亦絕對不會追討因磋商或授予貸款所引發或相關費用或收費。我們絕對無任何借貸利息之外的隱性收費，所有條款透明度極高。所見即所得，一目了然。",
  },
  {
    order: 3,
    question: "Q: LET'S GO私人貸款的信用評估參考甚麽？",
    answer: "A: LET'S GO 私人貸款的信用評估參考環聯 (TransUnion)的信貸評分。",
  },
  {
    order: 4,
    question: "Q: LET'S GO 貸款批核流程如何運作？",
    answer:
      "A: \"A.I. 級簡便申請，極速處理，網上貸款即批即過數* ：透過LET'S GO網上申請貸款，首先利用綁定的手機收到驗證碼。上載身份證以作記錄。驗證身份後，需要填寫個人資料。填妥及核對資料後，網上貸款即批，最快1秒就可收到審批結果(會提供私人貸款年利率及貸款還款期) 。身份及合約一經確認，網上貸款即時過數，以「轉數快」過戶到已核實的放款銀行戶口。如有需要，請提供以下其他證明#：固定薪金人士(例如: 政府公務員): 最近3個月收入證明 浮動薪金人士(例如: 地產銷售行業人士): 最近6個月收入證明 住址證明 * 網上確認貸款合約並完成最後資料核實及審批後，貸款即時以轉數快方式自動過戶，最後收款時間須按個別銀行安排而定。 指收到申請者的環聯信貸報告後計出報價的所需時間。申請者須符合指定要求，實際所需時間或會因個別情況而有所不同。# 免入息證明及住址證明只適用於貸款額最高為 HK$300,000，並符合特定信貸批核要求的客戶。按個別情況，LET'S GO有權要求客戶提供其他證明文件作貸款審批用途。如客戶提供額外申請文件，則可申請更高貸款金額。為核實申請人身份及安排放款，新客戶仍須網上提交身份證及銀行戶口或提款卡副本。\"",
  },
  {
    order: 5,
    question: "Q: 貸款批核時間要幾耐？",
    answer:
      "A: 只要完成網上貸款申請表，並準確附上所需申請文件，最快可即時看到批核結果，當中包括貸款額或貸款還款期或借貸利息及每月還款額。",
  },
]

export const data5 = [
  {
    order: 1,
    question: "Q:汽車貸款申請資格及所需文件?",
    answer:
      "A:申請資格\n\n1.香港居民\n2.在職人士\n3.18歲或以上\n所需文件\n\n1.香港永久性居民身份證\n2.住址證明\n  附有閣下姓名之最近三個月，其中一個月的住址證明\n3.收入證明 (如有)\n  最近三個月之糧單　或\n  本年度之薪俸稅單　或\n  其他工作及入息證明　或\n4.附有閣下姓名、賬戶號碼及薪酬之銀行月結單 / 存摺紀錄\n5.汽車牌簿\n6.汽車保險文件 (如有)\n7.心儀汽車相關資料\n* 若客戶提供額外證明文件，或有助取得更理想的貸款額。",
  },
  {
    order: 2,
    question: "Q:如何得知汽車貸款金額?",
    answer: "A:LET'S GO 會先評估汽車的價值作出貸款金額。",
  },
  {
    order: 3,
    question: "Q:可否用汽車作為抵押品，從而貸款？",
    answer:
      "A:LET'S GO 汽車貸款可分有抵押品和沒有抵押品 ，從而分析和批核貸款金額。",
  },
]
