import "./css/loanProduct.css"
import banner from "../images/mortgage/banner.jpg"
import banner_tablet768 from "../images/mortgage/banner_tablet768.png"
import banner_mobile425 from "../images/mortgage/banner_mobile425.png"
import ImageDesc from "../compoients/ImageDesc"
import "./css/mortgage.css"
import img1 from "../images/mortgage/point1.png"
import img2 from "../images/mortgage/point2.png"
import img3 from "../images/mortgage/point3.png"
import img4 from "../images/mortgage/point4.png"
import EstimateForm from "../compoients/EstimateForm"
import { ApplyBtnBig } from "../compoients/Btn"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import { useState } from "react"

const Mortgage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 961px)" })
  const isMobile_small = useMediaQuery({ query: "(max-width: 600px)" })
  const navigate = useNavigate()

  const backgroundImage = isMobile_small
    ? banner_mobile425
    : isTablet
    ? banner_tablet768
    : banner
  const [backgroundPositionY, setBackgroundPositionY] = useState("")
  useEffect(() => {
    if (backgroundImage != banner_tablet768) {
      setBackgroundPositionY("0")
    } else {
      setBackgroundPositionY("-100px")
    }
  }, [backgroundImage])

  return (
    <div className="loanProduct-container">
      <div
        className="loanProduct-banner"
        style={{
          backgroundImage: `url(${backgroundImage}`,
          backgroundPositionY: backgroundPositionY,
        }}
      >
        <div className="loanProduct-banner-text">
          <h1>物業按揭或業主貸款</h1>
          <hr class="loanProduct-banner-divider" />
          <p>可利用各種物業, 商鋪, 車位靈活套現</p>
          <p>貸款銀碼大, 還款年期長</p>
          <p>更容易計劃資金運用, 得心應手</p>
        </div>
      </div>
      <div className="loanProduct-content">
        <div className="mortgage-container">
          <div className="imageDesc-container">
            <ImageDesc
              image={img1}
              title={"物業按揭/業主貸款，任君選擇"}
              desc={
                "可以按唔同情況去選擇物業按揭/業主貸款即日得知批核結果，助你更容易運用資金"
              }
            />
            <ImageDesc
              image={img2}
              title={"估值最大，貸款額最多"}
              desc={
                "有專業物業估價員，貸款額最多可做到樓價八成，無需手續費，保證攞足"
              }
            />
          </div>
          <div className="imageDesc-container">
            <ImageDesc
              image={img3}
              title={"利息極低 還款期特長"}
              desc={"確保客人攞得容易，供得輕鬆期數最高可達72期"}
            />
            <ImageDesc
              image={img4}
              title={"任何物業均可申請"}
              desc={
                "不論私樓，未補地價居屋/公屋，村屋，車位，寫字樓以上所有申請均可受理"
              }
            />
          </div>
          <div className="mt-5 mb-5">
            <ApplyBtnBig color={"#632e8d"} handle={() => navigate("/apply")} />
          </div>
          <div className="loanProduct-eform">
            <EstimateForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mortgage
