import css from "./css/calculatorcreditcard.module.css"
import img_card from "../images/creditcard/card.png"
import img_arrrow from "../images/creditcard/arrow.png"
import { useState } from "react"
import CalculatorSlider from "./CalculatorSlider"
import { ApplyBtnBig } from "./Btn"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

const CalculatorCreditcard = () => {
  const navigate = useNavigate()
  const [amount, setAmount] = useState(0)
  const [minPay_interestAmount, setMinPay_interestAmount] = useState(0)
  const [minPay_monthlyPay, setMinPay_monthlyPay] = useState(0)
  const [minPay_periodYear, setMinPay_periodYear] = useState(0)
  const [minPay_periodMonth, setMinPay_periodMonth] = useState(0)

  const [letsgo_interestAmount, setLetsgo_interestAmount] = useState(0)
  const [letsgo_monthlyPay, setLetsgo_monthlyPay] = useState(0)
  const [letsgo_periodYear, setLetsgo_periodYear] = useState(0)
  const [letsgo_periodMonth, setLetsgo_periodMonth] = useState(0)
  const [newPeriodYear, setNewPeriodYear] = useState(0)
  const [newPeriodMonth, setNewPeriodMonth] = useState(0)

  const HandleAmount = (value) => {
    // setPrincipal(value)
    FormatMoney(value)
  }

  const FormatMoney = (value) => {
    setAmount(value)
    // setDisplayAmount(value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))
  }
  useEffect(() => {
    if (amount > 3500) {
      if (amount <= 3500) {
        setAmount(3500)
      }
      const minPay = MinPayInterestAmount(amount)
      setMinPay_interestAmount(minPay.interestAmount)
      setMinPay_monthlyPay(minPay.showMinPay)
      let periodYear = parseInt(minPay.period / 12)
      setMinPay_periodYear(periodYear)
      let periodMonth = parseInt(minPay.period % 12)
      setMinPay_periodMonth(periodMonth)

      const letsgoPlan = letsgoInterestAmount(amount)
      setLetsgo_interestAmount(letsgoPlan.interestAmount)
      setLetsgo_monthlyPay(letsgoPlan.monthlyPay)
      setLetsgo_periodYear(parseInt(letsgoPlan.period / 12))
      setLetsgo_periodMonth(letsgoPlan.period % 12)
      setNewPeriodYear(parseInt((minPay.period - letsgoPlan.period) / 12))
      setNewPeriodMonth(parseInt((minPay.period - letsgoPlan.period) % 12))
    } else {
      setMinPay_interestAmount(0)
      setMinPay_monthlyPay(0)
      setMinPay_periodYear(0)
      setMinPay_periodMonth(0)

      setLetsgo_interestAmount(0)
      setLetsgo_monthlyPay(0)
      setLetsgo_periodYear(0)
      setLetsgo_periodMonth(0)
    }
  }, [amount])

  function letsgoInterestAmount(P) {
    let amount = P
    let period = 48
    let monthlyRate = 0.008
    let monthlyPay = parseInt((amount * (1 + monthlyRate * period)) / 48)
    let interestAmount = parseInt(amount * monthlyRate * period)
    return { interestAmount, monthlyPay, period }
  }

  function MinPayInterestAmount(P) {
    let amount = P
    const instalmentRate = 1.023
    const minpayRate = 0.03
    let showMinPay = P * minpayRate
    const minCharge = 150
    let monthlyPay = 0
    let period = 0
    let interestAmount = P * minpayRate
    let count = true
    while (count) {
      if (monthlyPay > 0) {
        amount = (amount - monthlyPay) * instalmentRate
        monthlyPay = amount * minpayRate
        if (monthlyPay <= minCharge) {
          monthlyPay = minCharge
        }
        interestAmount = interestAmount + monthlyPay
      } else if (monthlyPay === 0) {
        monthlyPay = interestAmount
      }

      period++
      if (amount <= minCharge) {
        count = false
      }
    }

    interestAmount = parseInt(interestAmount - P)
    showMinPay = parseInt(showMinPay)

    return { interestAmount, showMinPay, period }
  }

  return (
    <div className={`${css.container} color3`}>
      <div className={css.section_container}>
        <div className={css.section1}>
          <div>
            <img src={img_card} alt="" className="me-2" />
            <span className="color3">卡數結欠</span>
          </div>
          <div className={css.money_input}>
            <span>HK $</span>
            <input
              type="text"
              maxLength={6}
              value={amount === 0 ? "" : amount}
              className="color2"
              onChange={(e) => {
                setAmount(e.target.value)
              }}
            />
          </div>
        </div>
        <div className={css.section2}>
          <CalculatorSlider
            max_value={500000}
            step_value={1000}
            setChangedValue={HandleAmount}
            inputChanged={amount}
            sliderColor={"#ff8106"}
          />
        </div>
        <div className={css.section3}>
          <div className={css.left}>
            <span>還信用卡Min Pay</span>
          </div>
          <div className={css.center}>
            <img src={img_arrrow} alt="" className="px-3" />
          </div>
          <div>
            <span className="color4">樂高</span>
            <span className="color1">信貸</span>清卡數貸款
          </div>
        </div>
        <hr className={css.divider} />

        <>
          <span className={css.title}>每月還款</span>
          <div className={css.detail}>
            <div className={css.left}>
              <span>${minPay_monthlyPay}</span>
            </div>
            <div className={css.center}>
              <img src={img_arrrow} alt="" className="px-3" />
            </div>
            <div className={css.right}>
              <span>${letsgo_monthlyPay}</span>
            </div>
            <div className="color5">
              <span>↓</span>
              <span>${minPay_monthlyPay - letsgo_monthlyPay}</span>
            </div>
          </div>
        </>

        <hr className={css.divider} />

        <>
          <span className={css.title}>利息支出</span>
          <div className={css.detail}>
            <div className={css.left}>
              <span>${minPay_interestAmount}</span>
            </div>
            <div className={css.center}>
              <img src={img_arrrow} alt="" className="px-3" />
            </div>
            <div className={css.right}>
              <span>${letsgo_interestAmount}</span>
            </div>
            <div className="color5">
              <span>↓</span>
              <span>${minPay_interestAmount - letsgo_interestAmount}</span>
            </div>
          </div>
        </>

        <hr className={css.divider} />

        <>
          <span className={css.title}>還款期數</span>
          <div className={css.detail}>
            <div className={css.left}>
              <span className="me-2">{minPay_periodYear}年</span>
              {minPay_periodMonth > 0 && <span>{minPay_periodMonth}月</span>}
            </div>
            <div className={css.center}>
              <img src={img_arrrow} alt="" className="px-3" />
            </div>
            <div className={css.right_period}>
              <span className="me-2">{letsgo_periodYear}年</span>
              {letsgo_periodMonth > 0 && <span>{letsgo_periodMonth}月</span>}
            </div>
            <div className="color5">
              <span>↓</span>
              <span className="me-2">{newPeriodYear}年</span>
              {newPeriodMonth > 0 && <span>{newPeriodMonth}月</span>}
            </div>
          </div>
        </>

        <div className="mt-4 mb-5">
          <ApplyBtnBig
            classColor={"color-orange"}
            handle={() => navigate("/apply")}
          />
        </div>
      </div>
    </div>
  )
}

export default CalculatorCreditcard
