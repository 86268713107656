import css from "./css/footer.module.css"
import logo from "../images/footer/logo_white.png"
import wtsapp_logo from "../images/icon/whatsapp_icon.png"
import fb_logo from "../images/icon/facebook.png"
import { HashLink } from "react-router-hash-link"
import { useMediaQuery } from "react-responsive"
import { useNavigate } from "react-router-dom"
import { searchDescByLabel } from "../utils/searchDescByLabel"

const Footer = ({ data }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" })
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 1024px)" })
  const navigate = useNavigate()

  const phone = data?.phone?.find((item) => item.label === "phone")?.description
  const whatsappNum = data?.whatsapp?.find(
    (item) => item.label === "whatsapp"
  )?.description
  const reportNum = data?.reportNum?.find(
    (item) => item.label === "reportNum"
  )?.description
  const license = data?.license?.find(
    (item) => item.label === "license"
  )?.description
  const remarks = data?.remarks?.find(
    (item) => item.label === "remarks"
  )?.description
  const fblink = data?.facebook?.find(
    (item) => item.label === "facebook"
  )?.description

  const advice_slogan = data?.advice_slogan?.find(
    (item) => item.label === "advice_slogan"
  )?.description

  const address = data?.address?.find(
    (item) => item.label === "address"
  )?.description
  function formatPhoneNumber(number) {
    if (!number || typeof number !== "string") {
      return "" // or return a default value or handle this case differently
    }
    return number.slice(0, 4) + "-" + number.slice(4)
  }

  return (
    <>
      {isDesktop || isTablet ? (
        <div className={css.container}>
          <div className={css.section1_container}>
            <div className={css.column1}>
              <a>
                <img
                  src={logo}
                  alt=""
                  className={css.logo}
                  onClick={() => {
                    navigate("/")
                  }}
                />
              </a>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td style={{ whiteSpace: "nowrap", verticalAlign: "top" }}>
                      公司地址：
                    </td>
                    <td>{address}</td>
                  </tr>

                  <tr>
                    <td style={{ whiteSpace: "nowrap", verticalAlign: "top" }}>
                      申請快線：
                    </td>
                    <td>
                      <a href={`tel:+852${phone}`}>
                        {formatPhoneNumber(phone)}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex mb-3 mt-3">
                <a
                  href={`https://api.whatsapp.com/send/?phone=852${whatsappNum}`}
                >
                  <img src={wtsapp_logo} alt="" className={css.social_logo} />
                </a>
                <a href={fblink}>
                  <img src={fb_logo} alt="" className={css.social_logo} />
                </a>
              </div>
              <p>放債人牌照號碼：{license}</p>© LETSGOCREDIT版權所有
              <p>
                {/* 註: 根據《放債人條例》年利率最高不超過48厘。
                供款期由6個月至個120月常見還款例子:
                年利率為30%情況下的貸款HK$10,000，
                還款期數12期，每期還款額為HK$975，總還款額為HK$11,700 */}
                {remarks}
              </p>
            </div>

            <div className={css.column2}>
              <h3 className="mb-4">貸款產品</h3>
              <HashLink smooth to="/privateloan">
                <p>私人貸款</p>
              </HashLink>
              <HashLink smooth to="/tu">
                <p>免TU貸款</p>
              </HashLink>
              <HashLink smooth to="/collateralLoan">
                <p>抵押品貸款</p>
              </HashLink>
              <HashLink smooth to="/mortgage">
                <p>業主貸款或物業按揭</p>
              </HashLink>
              <HashLink smooth to="/creditcard">
                <p>結餘轉戶或清卡數</p>
              </HashLink>
            </div>

            <div className={css.column3}>
              <div className={css.column3_1}>
                <HashLink smooth to="/blog">
                  <h3>信貸評級 (TU) 全攻略</h3>
                </HashLink>
                <br />
                <HashLink smooth to="/aboutus">
                  <h3>關於我們</h3>
                </HashLink>
                <br />
                <HashLink smooth to="/qa">
                  <h3>常見問題</h3>
                </HashLink>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3 ">
            <hr className={css.divider} />
          </div>
          <div className="d-flex flex-column align-items-center">
            <h1 className="mt-3">{advice_slogan}</h1>

            <p>
              投訴熱線：
              <a href={`tel:+852${reportNum}`}>
                {formatPhoneNumber(reportNum)}
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div className={css.container_mobile}>
          <div className={css.section1_container_mobile}>
            <a>
              <img
                src={logo}
                alt=""
                className={css.logo}
                onClick={() => {
                  navigate("/")
                }}
              />
            </a>
            <div className={css.page_link_mobile}>
              <h3 className="mb-4">貸款產品</h3>
              <HashLink smooth to="/privateloan">
                <p>私人貸款</p>
              </HashLink>
              <HashLink smooth to="/tu">
                <p>免TU貸款</p>
              </HashLink>
              <HashLink smooth to="/collateralLoan">
                <p>抵押品貸款</p>
              </HashLink>
              <HashLink smooth to="/mortgage">
                <p>業主貸款或物業按揭</p>
              </HashLink>
              <HashLink smooth to="/creditcard">
                <p>結餘轉戶或清卡數</p>
              </HashLink>
              <HashLink smooth to="/blog">
                <p>信貸評級 (TU) 全攻略</p>
              </HashLink>
              <HashLink smooth to="/aboutus">
                <p>關於我們</p>
              </HashLink>
              <HashLink smooth to="/qa">
                <p>常見問題</p>
              </HashLink>
            </div>
            <p className="p-1">{address}</p>
            <p>
              申請快線：
              <a href={`tel:+852${phone}`}>{formatPhoneNumber(phone)}</a>
            </p>
            <div className={css.social_logo_wrapper}>
              <a
                href={`https://api.whatsapp.com/send/?phone=852${whatsappNum}`}
              >
                <img
                  src={wtsapp_logo}
                  alt="whatsapplogo"
                  className={`${css.social_logo_mobile} me-2`}
                />
              </a>
              <a href={fblink}>
                <img
                  src={fb_logo}
                  alt="facebooklogo"
                  className={css.social_logo_mobile}
                />
              </a>
            </div>
            <p>放債人牌照號碼：{license}</p>© LETSGOCREDIT版權所有
            <p>
              {remarks}
              {/* 註: 根據《放債人條例》年利率最高不超過48厘。
              供款期由6個月至個120月常見還款例子:
              年利率為30%情況下的貸款HK$10,000，
              還款期數12期，每期還款額為HK$975，總還款額為HK$11,700 */}
            </p>
            <hr className={css.divider} />
            <div className="d-flex flex-column align-items-center">
              <h1 className="mt-3" style={{ fontSize: "1.5rem" }}>
                {advice_slogan}
              </h1>

              <p>
                投訴熱線：
                <a href={`tel:+852${reportNum}`}>
                  {formatPhoneNumber(reportNum)}
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Footer
