import { useEffect } from "react"
import "./css/loanProduct.css"
import banner from "../images/creditcard/banner.jpg"
import banner_tablet768 from "../images/creditcard/banner_tablet768.png"
import banner_mobile425 from "../images/creditcard/banner_mobile425.png"
import people_img from "../images/creditcard/people.png"
import "./css/creditcard.css"
import "./css/collateralLoan.css"
import ImageDescHoriz from "../compoients/ImageDescHoriz"
import ImageHorizontalDesc from "../compoients/ImageHorizontalDesc"
import img1 from "../images/creditcard/point1.png"
import img2 from "../images/creditcard/point2.png"
import { ApplyBtnBig } from "../compoients/Btn"
import CreditCardSwiper from "../compoients/CreditCardSwiper"
import CalculatorCreditcard from "../compoients/CalculatorCreditcard"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"

const CreditCard = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" })
  }, [])

  const navigate = useNavigate()
  const isTablet = useMediaQuery({ query: "(max-width: 960px)" })
  const isDesktop = useMediaQuery({ query: "(min-width: 961px)" })
  const isMobile_small = useMediaQuery({ query: "(max-width: 600px)" })

  const backgroundImage = isMobile_small
    ? banner_mobile425
    : isTablet
    ? banner_tablet768
    : banner

  return (
    <div className="loanProduct-container">
      <div
        className="loanProduct-banner"
        style={{ backgroundImage: `url(${backgroundImage}` }}
      >
        <div className="loanProduct-banner-text">
          <h1>結餘整合</h1>
          <hr class="loanProduct-banner-divider" />
          <p>為你籌謀獻策, 將所有貸款集中處理,</p>
          <p>為你慳點利息, 少一點付出, 多一點滿足</p>
          <p>貼心打造定制一個最合適你的供款計劃</p>
        </div>
      </div>
      <div className="collateralLoan-content">
        <div className="collateralLoan-imageBlock">
          <ImageHorizontalDesc
            image={img1}
            width={150}
            title={"減少不必要的罰款及影響信貸評級"}
            desc={
              "客人可能為應付各種貸款到期，曾一時大意忘記供款而影響到信貸評級？處理煩鎖，費時失事，我地可以為你設計一個輕鬆供款計劃，加快提升信貸評級，令你貸款愈借愈輕鬆"
            }
          />
          <ImageHorizontalDesc
            image={img2}
            width={150}
            title={"減少不必要的卡數利息支出"}
            desc={
              "卡數一筆清 一 眾所周知，每月找卡數最低還款額，效益極低，事倍功半，最後利息總支出為貸款額十倍，所以我地為客人提供一站式服務，將卡數有計劃地集中清還，解除供最低還款額束縛，陪你計劃將來。"
            }
          />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-5 mb-5">
        <ApplyBtnBig color={"#632e8d"} handle={() => navigate("/apply")} />
      </div>
      <div className="creditcard-section2 mt-5 ">
        <div className="creditcard-section2-container">
          <div className="creditcard-calculator-container-people">
            {/* <CreditCardSwiper /> */}
            <img src={people_img} alt="staff" />
          </div>

          <div className="creditcard-calculator-container">
            <CalculatorCreditcard />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditCard
