import styles from "../../css/UI.module.css"
import Profile from "../Profile/Profile"
import Button from "@mui/material/Button"
import { useNavigate, useParams } from "react-router"
import { Form, FormControl, InputGroup } from "react-bootstrap"
import "./clientDetail.css"
import { uploadFile, getUserAllDocs, deleteUserDoc } from "../../firebaseConf"
import { useEffect, useState } from "react"
import { Table, Text, Spinner, Box } from "gestalt"
import "gestalt/dist/gestalt.css"
import { confirmAlert } from "react-confirm-alert"
import { TextField } from "@mui/material"
import CreateContract from "../CreateContract/CreateContract"
import EditContract from "../EditContract/EditContract"

const ClientDetail = () => {
  const [modifiedPdf, setModifiedPdf] = useState(null)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [docs, setDocs] = useState([])
  const [show, setShow] = useState(true)
  const [loanNo, setLoanNo] = useState("")
  const [borrower, setBorrower] = useState("")
  const [isModifiedPdf, setIsModifiedPdf] = useState(false)

  let num = 0
  let { id } = useParams()

  useEffect(() => {
    if (modifiedPdf) {
      setIsModifiedPdf(true)
    }
  }, [modifiedPdf])

  const handleUpload = (e) => {
    e.preventDefault()
    setIsModifiedPdf(false)

    // if (e.target[0].files.length === 0) {
    //   alert("請選擇上載文件")
    //   return
    // }

    // if (loanNo === "" && borrower === "") {
    //   alert("請輸入貸款號碼及借款人")
    //   return
    // }
    setShow(true)

    setError(false)
    setUploadSuccess(false)
    // const file = e.target[0].files[0]

    async function uploadDocs() {
      const rs = await uploadFile(modifiedPdf, id)
      if (rs === "success") {
        setUploadSuccess(true)
        setError(false)
      } else {
        setError(true)
        setUploadSuccess(false)
      }
      setShow(false)
    }

    setTimeout(uploadDocs, 500)
    setTimeout(() => {
      loadData()
    }, 2000)
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    setShow(true)
    async function getUserDocs() {
      const docs_selector = await getUserAllDocs(id)
      setDocs(docs_selector)
      setShow(false)
    }
    setTimeout(getUserDocs, 3500)
  }

  const handleDeleteDoc = (e, fileName) => {
    e.preventDefault()
    async function deleteDoc() {
      const rs = await deleteUserDoc(id, fileName)
      loadData()
    }
    confirmAlert({
      title: "刪除文件",
      message: "請確認是否刪除" + fileName + "?",
      buttons: [
        {
          label: "確認",
          onClick: () => {
            setTimeout(deleteDoc, 1000)
          },
        },
        {
          label: "取消",
          onClick: () => {
            return
          },
        },
      ],
    })
  }

  return (
    <div className={styles.container}>
      <Profile back_url={"/clientslist"} logoLink="/clientslist" />
      {show ? (
        <Spinner show={show} accessibilityLabel="spinner" />
      ) : (
        <>
          {/* <CreateContract /> */}
          <div className="input_container">
            <br />

            {/* <h2>上載客人需要簽署的文件</h2> */}
            {/* <hr className={styles.divider}></hr> */}
            <br />
            {/* <form onSubmit={handleUpload}>
              <div className="upload-doc-row">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Control type="file" />
                  {uploadSuccess && <Form.Label>上載完成</Form.Label>}
                  {error && <Form.Label>上載失敗</Form.Label>}
                  <br />
                </Form.Group>
                <Button
                  type="submit"
                  variant="contained"
                  className={styles.list_button}
                  disabled={show}
                >
                  上載
                </Button>
              </div>
            </form> */}

            <br />
            <h2>未簽文件</h2>

            <hr className={styles.divider}></hr>
            <Box width="100%">
              <Table
                accessibilityLabel="Sticky header"
                maxHeight={500}
                stickyColumns={1}
              >
                <Table.Header sticky>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Text weight="bold">文件名稱</Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold">上載時間</Text>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <Text weight="bold"></Text>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {docs[0]?.files
                    .filter((doc) => doc?.fileName.includes("_contract.pdf"))
                    .reverse()
                    .map((doc) => {
                      return (
                        <Table.Row key={doc?.docId}>
                          <Table.Cell>
                            <a href={doc?.downloadURL} target="_blank">
                              <p>
                                {++num}. {doc?.fileName}
                              </p>
                            </a>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{doc?.timestamp}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              variant="contained"
                              className={styles.list_button}
                              onClick={(e) => {
                                handleDeleteDoc(e, doc?.fileName)
                              }}
                            >
                              刪除
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                </Table.Body>
              </Table>
            </Box>
          </div>
          <div className="d-flex flex-column align-items-center mt-1">
            <EditContract setModifiedPdf={setModifiedPdf} />
            <Button
              className={`${styles.signCanvas_button} mt-5 mb-5`}
              variant="contained"
              style={{ width: "100px" }}
              onClick={handleUpload}
              disabled={!isModifiedPdf}
            >
              上載PDF
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default ClientDetail
